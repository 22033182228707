import React from "react";

const PastPresent = () => {
  return (
    <main className="c-Page_Main">
      <header className="c-Banner">
        <div
          className="c-Banner_Inner is-loaded is-background"
          data-src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2018/10/19153531/priestley-construction-banner-03.jpg"
          style={{
            backgroundImage:
              "url(https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2018/10/19153531/priestley-construction-banner-03.jpg)",
          }}
        >
          <div className="o-Container c-Banner_Content">
            <h1 className="c-Banner_Title">Past, Present, Future</h1>
            <h3 className="o-M30 o-P20 c-Banner_Subheading">
              From one man to an acclaimed, award-winning team. We will always
              keep challenging ourselves
            </h3>
          </div>
        </div>
      </header>
      <div className="o-Section o-Section-bg-grey-lighter">
        <div className="o-Container">
          <div className="o-Flex o-Flex-center o-Section_Cols">
            <div className="o-Section_Col c-Content c-Content-limit">
              <h2>Taking the right risks</h2>
              <p>
                Our brand is the brainchild of Nathan Priestley, who shifted
                from a career in professional rugby to the real estate playing
                field. After immense success working in the industry for other
                companies, he set up Priestley Construction in 2012, following
                the successful launch of Priestley’s agency and consultancy.
                Thanks to a unique offering and personal standing in the
                industry, he secured over £1m of construction contracts in his
                first year. Priestley Construction has been going from strength
                to strength ever since.
              </p>
            </div>
            <div className="o-Section_Col">
              <figure className="c-Figure c-Figure-right">
                <img
                  src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2018/05/21014712/priestley-construction-under-construction-03.jpg"
                  className="c-Image c-Image-full-width"
                  loading="lazy"
                  width="1010"
                  height="672"
                  title="priestley-construction-under-construction-03"
                  alt=""
                />
              </figure>
            </div>
          </div>
        </div>
      </div>
      <div className="o-Section o-Section-bg-grey-lighter">
        <div className="o-Container">
          <div className="o-Flex o-Flex-center o-Section_Cols o-Section_Cols-reverse">
            <div className="o-Section_Col c-Content c-Content-limit">
              <h2>How we work now</h2>
              <p>
                Nathan’s goal was to bring everything in house, giving clients
                every necessary skill for a smart, tailor-made project. And
                that’s exactly what we provide, managing a project in its
                entirety or seamlessly integrating with other contractors. Our
                team are experts in major construction and converting old,
                dilapidated buildings into award-winning developments – always
                on time, budget and without compromise. From feasibility design,
                to delivery and everything in between, we are not aware of any
                other main contractor in the UK who can offer this end to end
                service in house.
              </p>
            </div>
            <div className="o-Section_Col">
              <figure className="c-Figure c-Figure-left">
                <img
                  src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2018/04/19150641/priestley-construction-testimonial1.jpg"
                  className="c-Image c-Image-full-width"
                  loading="lazy"
                  width="700"
                  height="477"
                  title="priestley-construction-testimonial1"
                  alt=""
                />
              </figure>
            </div>
          </div>
        </div>
      </div>
      <div className="o-Section o-Section-bg-grey-lighter">
        <div className="o-Container">
          <div className="o-Flex o-Flex-center o-Section_Cols">
            <div className="o-Section_Col c-Content c-Content-limit">
              <h2>Where we’re headed</h2>
              <p>
                Priestley Construction is already an esteemed name in the
                British market. But we have an international presence that is
                due to expand. We’ve got our sights set on launching several
                offices in Hong Kong, Singapore, Dubai and mainland China,
                helping us to interact more closely with overseas developers,
                meeting their requirements with greater intensity in the UK
                property market.
              </p>
            </div>
            <div className="o-Section_Col">
              <figure className="c-Figure c-Figure-right">
                <img
                  src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2018/05/21012717/priestley-construction-stableford-hall.jpg"
                  className="c-Image c-Image-full-width"
                  loading="lazy"
                  width="1010"
                  height="672"
                  title="priestley-construction-stableford-hall"
                  alt=""
                />
              </figure>
            </div>
          </div>
        </div>
      </div>

      <div className="o-Section o-Section-bg-grey-lighter o-Section-no-switch">
        <div className="o-Center c-Newsletter">
          <h2 className="o-M30 c-Title u-TextAlignCenter">Hear more from us</h2>
          <p className="o-M30 u-TextAlignCenter">
            Get the inside scoop on our VIP launches, early bird offers, latest
            projects, company news and more.
          </p>
        </div>
      </div>
    </main>
  );
};

export default PastPresent;
