import axios from "axios";
import React, { useEffect, useState } from "react";
import { CobtactDataProps, SiteDataProps } from "../../models/LayoutModels";
import { API_URL, IMAGE_URL } from "../../settings/config";

const ContactUs = () => {
  const [siteData, setSiteData] = useState<SiteDataProps>({});
  const [contactsData, setcontactsData] = useState<CobtactDataProps[]>([]);
  const [error, setError] = useState("");
  useEffect(() => {
    const fetchSiteData = async () => {
      try {
        const res = await axios.get(`${API_URL}/get-settings`);
        setSiteData(res.data.data);
      } catch (err: unknown) {
        if (err instanceof Error) {
          setError(err.message);
          setError("");
        } else {
          setError("Something went wrong");
        }
      }
    };
    const fetchContactData = async () => {
      try {
        const res = await axios.get(
          `${API_URL}/get-contacts`
        );
        setcontactsData(res.data.data);
      } catch (err: unknown) {
        if (err instanceof Error) {
          setError(err.message);
          setError("");
        } else {
          setError("Something went wrong");
        }
      }
    };
    fetchSiteData();
    fetchContactData();
  }, []);
  console.log(error);

  return (
    <main className="c-Page_Main">
      <header className="o-Section o-Section-bg-grey-lighter c-PageBanner">
        <div className="o-Container">
          <h1 className="c-Title c-PageBanner_Title">
            {siteData?.contact_page_title}
          </h1>
          <h2 className="o-P20 c-Title c-PageBanner_Subtitle">
            {siteData?.contact_page_description}
          </h2>
        </div>
      </header>
      <div className="o-Section o-Section-bg-grey-lighter">
        <div className="o-Container">
          {contactsData?.map((item) => (
            <div className="o-M30 o-Flex o-Flex-wrap c-Contact" key={item.id}>
              <div className="c-Contact_Col c-Contact_Col-half">
                <h4 className="o-M10 c-Title c-Contact_Title">{item?.name}</h4>
              </div>
              <div className="c-Contact_Col">
                <h5 className="o-M10 c-Title c-Contact_Subtitle">Address</h5>
                <address className="c-Contact_Address">
                  <a href="/" target="_blank">
                    <span className="u-Underline u-Underline-push">
                      {item?.address}
                    </span>
                    <br />
                  </a>
                </address>
              </div>
              <div className="c-Contact_Col">
                <h5 className="o-M10 c-Title c-Contact_Subtitle">Phone</h5>
                <span className="o-M20 u-Block">{item?.phone}</span>
                <h5 className="o-M10 c-Title c-Contact_Subtitle">Email</h5>

                <span className="__cf_email__">{item?.email}</span>
              </div>
            </div>
          ))}

          <img
            src={`${IMAGE_URL}/${siteData?.contact_page_banner}`}
            className="c-Image c-Image-full-width"
            loading="lazy"
            width="1800"
            height="728"
            title="priestley-construction-office"
            alt=""
          />
        </div>
      </div>

      <div className="o-Section o-Section-bg-grey-lighter o-Section-no-switch">
        <div className="o-Center c-Newsletter">
          <h2 className="o-M30 c-Title u-TextAlignCenter">Hear more from us</h2>
          <p className="o-M30 u-TextAlignCenter">
            Get the inside scoop on our VIP launches, early bird offers, latest
            projects, company news and more.
          </p>
        </div>
      </div>
    </main>
  );
};

export default ContactUs;
