import React from "react";

const CompletedProject = () => {
  return (
    <main className="c-Page_Main c-Page_Main-dark">
      <header className="o-Section o-Section-bg-grey-lighter c-PageBanner">
        <div className="o-Container">
          <h1 className="c-Title c-PageBanner_Title">Completed Projects</h1>
          <h2 className="o-P20 c-Title c-PageBanner_Subtitle">
            We love watching our work deliver on its promise. Renovating
            historic sites and completing functional and stylish homes ahead of
            the average schedule. Around the UK, there are hundreds of
            properties with our name on the building specs. Here are just a few
          </h2>
        </div>
      </header>
      <div className="o-Section o-Section-bg-grey-lighter">
        <div className="o-Container">
          <ul className="o-M100 o-Flex o-Flex-wrap o-Flex-gutters c-ImageCards">
            <li className="o-Flex_Block c-ImageCards_Item">
              <a href="https://priestleyconstruction.co.uk/project/conditioning-house-phase-4/">
                <img
                  src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2022/10/Conditioning-House-700x458.jpg"
                  className="c-Image o-M15 c-Image-full-width"
                  loading="lazy"
                  width="700"
                  height="458"
                  title="Conditioning House"
                  alt=""
                />
                <h3 className="c-Title">Conditioning House Phase 4</h3>
                <span className="o-M10 c-Title c-ImageCards_Subtitle u-ColorBrand">
                  Bradford
                </span>
              </a>
            </li>
            <li className="o-Flex_Block c-ImageCards_Item">
              <a href="https://priestleyconstruction.co.uk/project/12-piccadilly/">
                <img
                  src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2022/10/AJS4778-700x458.jpg"
                  className="c-Image o-M15 c-Image-full-width"
                  loading="lazy"
                  width="700"
                  height="458"
                  title="_AJS4778"
                  alt=""
                />
                <h3 className="c-Title">12 Piccadilly</h3>
                <span className="o-M10 c-Title c-ImageCards_Subtitle u-ColorBrand">
                  Bradford
                </span>
              </a>
            </li>
            <li className="o-Flex_Block c-ImageCards_Item">
              <a href="https://priestleyconstruction.co.uk/project/cablestreet/">
                <img
                  src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2020/04/Cable-St-front-external-700x458.jpg"
                  className="c-Image o-M15 c-Image-full-width"
                  loading="lazy"
                  width="700"
                  height="458"
                  title="Cable St front external"
                  alt=""
                />
                <h3 className="c-Title">Cable Street</h3>
                <span className="o-M10 c-Title c-ImageCards_Subtitle u-ColorBrand">
                  Lancaster
                </span>
              </a>
            </li>
            <li className="o-Flex_Block c-ImageCards_Item">
              <a href="https://priestleyconstruction.co.uk/project/cliff-oaks-a-new-private-gate-development-in-leeds/">
                <img
                  src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2020/02/1-700x458.jpg"
                  className="c-Image o-M15 c-Image-full-width"
                  loading="lazy"
                  width="700"
                  height="458"
                  title="1"
                  alt=""
                />
                <h3 className="c-Title">Cliff Oaks</h3>
                <span className="o-M10 c-Title c-ImageCards_Subtitle u-ColorBrand">
                  Leeds
                </span>
              </a>
            </li>
            <li className="o-Flex_Block c-ImageCards_Item">
              <a href="https://priestleyconstruction.co.uk/project/the-old-post-office-building/">
                <img
                  src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2018/05/C-700x458.jpg"
                  className="c-Image o-M15 c-Image-full-width"
                  loading="lazy"
                  width="700"
                  height="458"
                  title="C"
                  alt=""
                />
                <h3 className="c-Title">The Old Post Office building</h3>
                <span className="o-M10 c-Title c-ImageCards_Subtitle u-ColorBrand">
                  Warrington
                </span>
              </a>
            </li>
            <li className="o-Flex_Block c-ImageCards_Item">
              <a href="https://priestleyconstruction.co.uk/project/premium-offices/">
                <img
                  src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2020/03/01104932/The-Bridge-Inn-1-700x458.jpg"
                  className="c-Image o-M15 c-Image-full-width"
                  loading="lazy"
                  width="700"
                  height="458"
                  title="The Bridge Inn 1"
                  alt=""
                />
                <h3 className="c-Title">Premium Offices</h3>
                <span className="o-M10 c-Title c-ImageCards_Subtitle u-ColorBrand">
                  Wakefield
                </span>
              </a>
            </li>
            <li className="o-Flex_Block c-ImageCards_Item">
              <a href="https://priestleyconstruction.co.uk/project/conditioning-house-project/">
                <img
                  src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2018/04/21161237/conditioning_house_external-700x458.jpg"
                  className="c-Image o-M15 c-Image-full-width"
                  loading="lazy"
                  width="700"
                  height="458"
                  title="conditioning_house_external"
                  alt="Bradford&#039;s grade 2 listed conditioning house building"
                />
                <h3 className="c-Title">Conditioning House Bradford</h3>
                <span className="o-M10 c-Title c-ImageCards_Subtitle u-ColorBrand">
                  Bradford
                </span>
              </a>
            </li>
            <li className="o-Flex_Block c-ImageCards_Item">
              <a href="https://priestleyconstruction.co.uk/project/hanover-house/">
                <img
                  src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2018/04/20135316/hanover-house-06-700x458.jpg"
                  className="c-Image o-M15 c-Image-full-width"
                  loading="lazy"
                  width="700"
                  height="458"
                  title="hanover-house-06"
                  alt=""
                />
                <h3 className="c-Title">Hanover House</h3>
                <span className="o-M10 c-Title c-ImageCards_Subtitle u-ColorBrand">
                  Leeds
                </span>
              </a>
            </li>
            <li className="o-Flex_Block c-ImageCards_Item">
              <a href="https://priestleyconstruction.co.uk/project/kensington/">
                <img
                  src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2020/04/01111544/WhatsApp-Image-2020-03-25-at-13.35.40-700x458.jpeg"
                  className="c-Image o-M15 c-Image-full-width"
                  loading="lazy"
                  width="700"
                  height="458"
                  title="WhatsApp Image 2020-03-25 at 13.35.40"
                  alt=""
                />
                <h3 className="c-Title">Kensington</h3>
                <span className="o-M10 c-Title c-ImageCards_Subtitle u-ColorBrand">
                  London
                </span>
              </a>
            </li>
            <li className="o-Flex_Block c-ImageCards_Item">
              <a href="https://priestleyconstruction.co.uk/project/green-lane-mill/">
                <img
                  src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2019/10/21003020/img_7012-img_7014-700x458.jpg"
                  className="c-Image o-M15 c-Image-full-width"
                  loading="lazy"
                  width="700"
                  height="458"
                  title="Green lane Mill project"
                  alt="priestley construction Green Lane Mill project"
                />
                <h3 className="c-Title">Green Lane Mill</h3>
                <span className="o-M10 c-Title c-ImageCards_Subtitle u-ColorBrand">
                  Holmfirth
                </span>
              </a>
            </li>
            <li className="o-Flex_Block c-ImageCards_Item">
              <a href="https://priestleyconstruction.co.uk/project/old-police-station/">
                <img
                  src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2019/07/06184849/priestley-group-old-police-station-700x458.jpg"
                  className="c-Image o-M15 c-Image-full-width"
                  loading="lazy"
                  width="700"
                  height="458"
                  title="priestley-construction-old-police-station"
                  alt="Priestley construction old police station conversion"
                />
                <h3 className="c-Title">Old Police Station</h3>
                <span className="o-M10 c-Title c-ImageCards_Subtitle u-ColorBrand">
                  Milnrow
                </span>
              </a>
            </li>
            <li className="o-Flex_Block c-ImageCards_Item">
              <a href="https://priestleyconstruction.co.uk/project/islinghaus-apartments-angel-london/">
                <img
                  src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2018/11/26151026/priestley-group-islinghaus-banner-new-700x458.jpg"
                  className="c-Image o-M15 c-Image-full-width"
                  loading="lazy"
                  width="700"
                  height="458"
                  title="priestley-construction-islinghaus-kitchen view"
                  alt="Islinghaus Apartments in Islington"
                />
                <h3 className="c-Title">
                  Islinghaus Apartments, Angel, London
                </h3>
                <span className="o-M10 c-Title c-ImageCards_Subtitle u-ColorBrand">
                  London
                </span>
              </a>
            </li>
          </ul>
          <nav className="c-Pagination">
            <span
              aria-current="page"
              className="c-Btn c-Pagination_Link c-Pagination_Link-current"
            >
              1
            </span>
            <a
              className="c-Btn c-Pagination_Link"
              href="https://priestleyconstruction.co.uk/projects/status/completed/page/2/"
            >
              2
            </a>
            <a
              className="c-Btn c-Pagination_Link"
              href="https://priestleyconstruction.co.uk/projects/status/completed/page/3/"
            >
              3
            </a>
            <a
              className="c-Btn c-Pagination_Link c-Pagination_Link-next"
              href="https://priestleyconstruction.co.uk/projects/status/completed/page/2/"
            >
              &rarr;
            </a>
            <span className="c-Pagination_Count">
              3 pages with 25 developments
            </span>
          </nav>
          <div className="u-TextAlignCenter">
            <h2 className="o-M4 c-Title">Current projects</h2>
            <a
              href="https://priestleyconstruction.co.uk/projects/status/current/"
              className="o-Flex_Push c-Btn c-Btn-clean c-Btn-light"
            >
              View Projects &nbsp;&nbsp;
              <span className="c-Icon c-Icon-long-arrow-right"></span>
            </a>
          </div>
        </div>
      </div>
      <div className="o-Section o-Section-bg-grey-lighter o-Section-no-switch">
        <div className="o-Center c-Newsletter">
          <h2 className="o-M30 c-Title u-TextAlignCenter">Hear more from us</h2>
          <p className="o-M30 u-TextAlignCenter">
            Get the inside scoop on our VIP launches, early bird offers, latest
            projects, company news and more.
          </p>
        </div>
      </div>
    </main>
  );
};

export default CompletedProject;
