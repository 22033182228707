import React from "react";

const Recidential = () => {
  return (
    <main className="c-Page_Main">
      <header className="o-Section o-Section-bg-grey-lighter c-PageBanner">
        <div className="o-Container">
          <h1 className="c-Title c-PageBanner_Title">Residential</h1>
        </div>
      </header>
      <div className="o-Section o-Section-bg-grey-lighter">
        <div className="o-Container">
          <ul className="o-M100 o-Flex o-Flex-wrap o-Flex-gutters c-ImageCards">
            <li className="o-Flex_Block c-ImageCards_Item">
              <a href="https://priestleyconstruction.co.uk/project/12-piccadilly/">
                <img
                  src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2022/10/AJS4778-700x458.jpg"
                  className="c-Image o-M15 c-Image-full-width"
                  loading="lazy"
                  width="700"
                  height="458"
                  title="_AJS4778"
                  alt=""
                />
                <h3 className="c-Title">12 Piccadilly</h3>
                <span className="o-M10 c-Title c-ImageCards_Subtitle u-ColorBrand">
                  Bradford
                </span>
              </a>
            </li>
            <li className="o-Flex_Block c-ImageCards_Item">
              <a href="https://priestleyconstruction.co.uk/project/the-paddocks/">
                <img
                  src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2022/10/Knuts-VP5-RevB-gigapixel-standard-scale-2_00x-700x458.jpg"
                  className="c-Image o-M15 c-Image-full-width"
                  loading="lazy"
                  width="700"
                  height="458"
                  title="Knuts VP5 RevB-gigapixel-standard-scale-2_00x"
                  alt=""
                />
                <h3 className="c-Title">The Paddocks</h3>
                <span className="o-M10 c-Title c-ImageCards_Subtitle u-ColorBrand">
                  Knutsford
                </span>
              </a>
            </li>
            <li className="o-Flex_Block c-ImageCards_Item">
              <a href="https://priestleyconstruction.co.uk/project/conditioning-house-phase-4/">
                <img
                  src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2022/10/Conditioning-House-700x458.jpg"
                  className="c-Image o-M15 c-Image-full-width"
                  loading="lazy"
                  width="700"
                  height="458"
                  title="Conditioning House"
                  alt=""
                />
                <h3 className="c-Title">Conditioning House Phase 4</h3>
                <span className="o-M10 c-Title c-ImageCards_Subtitle u-ColorBrand">
                  Bradford
                </span>
              </a>
            </li>
            <li className="o-Flex_Block c-ImageCards_Item">
              <a href="https://priestleyconstruction.co.uk/project/marygate-house/">
                <img
                  src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2021/05/05095141/DSC_0736-700x458.jpg"
                  className="c-Image o-M15 c-Image-full-width"
                  loading="lazy"
                  width="700"
                  height="458"
                  title="Marygate House in the heart of Wakefield city centre"
                  alt="Marygate House in the heart of Wakefield city centre"
                />
                <h3 className="c-Title">Marygate House</h3>
                <span className="o-M10 c-Title c-ImageCards_Subtitle u-ColorBrand">
                  Wakefield
                </span>
              </a>
            </li>
            <li className="o-Flex_Block c-ImageCards_Item">
              <a href="https://priestleyconstruction.co.uk/project/luxury-apartments-coming-to-great-george-street-leeds/">
                <img
                  src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2021/05/04163422/DSC_0146_rev-700x458.jpg"
                  className="c-Image o-M15 c-Image-full-width"
                  loading="lazy"
                  width="700"
                  height="458"
                  title="Great George Street building in Leeds"
                  alt="Great George Street building in Leeds"
                />
                <h3 className="c-Title">Great George Street</h3>
                <span className="o-M10 c-Title c-ImageCards_Subtitle u-ColorBrand">
                  Leeds
                </span>
              </a>
            </li>
            <li className="o-Flex_Block c-ImageCards_Item">
              <a href="https://priestleyconstruction.co.uk/project/kensington/">
                <img
                  src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2020/04/01111544/WhatsApp-Image-2020-03-25-at-13.35.40-700x458.jpeg"
                  className="c-Image o-M15 c-Image-full-width"
                  loading="lazy"
                  width="700"
                  height="458"
                  title="WhatsApp Image 2020-03-25 at 13.35.40"
                  alt=""
                />
                <h3 className="c-Title">Kensington</h3>
                <span className="o-M10 c-Title c-ImageCards_Subtitle u-ColorBrand">
                  London
                </span>
              </a>
            </li>
            <li className="o-Flex_Block c-ImageCards_Item">
              <a href="https://priestleyconstruction.co.uk/project/cliff-oaks-a-new-private-gate-development-in-leeds/">
                <img
                  src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2020/02/1-700x458.jpg"
                  className="c-Image o-M15 c-Image-full-width"
                  loading="lazy"
                  width="700"
                  height="458"
                  title="1"
                  alt=""
                />
                <h3 className="c-Title">Cliff Oaks</h3>
                <span className="o-M10 c-Title c-ImageCards_Subtitle u-ColorBrand">
                  Leeds
                </span>
              </a>
            </li>
            <li className="o-Flex_Block c-ImageCards_Item">
              <a href="https://priestleyconstruction.co.uk/project/green-lane-mill/">
                <img
                  src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2019/10/21003020/img_7012-img_7014-700x458.jpg"
                  className="c-Image o-M15 c-Image-full-width"
                  loading="lazy"
                  width="700"
                  height="458"
                  title="Green lane Mill project"
                  alt="priestley construction Green Lane Mill project"
                />
                <h3 className="c-Title">Green Lane Mill</h3>
                <span className="o-M10 c-Title c-ImageCards_Subtitle u-ColorBrand">
                  Holmfirth
                </span>
              </a>
            </li>
            <li className="o-Flex_Block c-ImageCards_Item">
              <a href="https://priestleyconstruction.co.uk/project/old-police-station/">
                <img
                  src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2019/07/06184849/priestley-group-old-police-station-700x458.jpg"
                  className="c-Image o-M15 c-Image-full-width"
                  loading="lazy"
                  width="700"
                  height="458"
                  title="priestley-construction-old-police-station"
                  alt="Priestley construction old police station conversion"
                />
                <h3 className="c-Title">Old Police Station</h3>
                <span className="o-M10 c-Title c-ImageCards_Subtitle u-ColorBrand">
                  Milnrow
                </span>
              </a>
            </li>
            <li className="o-Flex_Block c-ImageCards_Item">
              <a href="https://priestleyconstruction.co.uk/project/jameson-house/">
                <img
                  src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2018/12/18230813/priestley-construction-jameson-house-01-700x458.jpg"
                  className="c-Image o-M15 c-Image-full-width"
                  loading="lazy"
                  width="700"
                  height="458"
                  title="priestley-construction-jameson-house-01"
                  alt=""
                />
                <h3 className="c-Title">Jameson House</h3>
                <span className="o-M10 c-Title c-ImageCards_Subtitle u-ColorBrand">
                  Sunderland
                </span>
              </a>
            </li>
            <li className="o-Flex_Block c-ImageCards_Item">
              <a href="https://priestleyconstruction.co.uk/project/islinghaus-apartments-angel-london/">
                <img
                  src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2018/11/26151026/priestley-group-islinghaus-banner-new-700x458.jpg"
                  className="c-Image o-M15 c-Image-full-width"
                  loading="lazy"
                  width="700"
                  height="458"
                  title="priestley-construction-islinghaus-kitchen view"
                  alt="Islinghaus Apartments in Islington"
                />
                <h3 className="c-Title">
                  Islinghaus Apartments, Angel, London
                </h3>
                <span className="o-M10 c-Title c-ImageCards_Subtitle u-ColorBrand">
                  London
                </span>
              </a>
            </li>
            <li className="o-Flex_Block c-ImageCards_Item">
              <a href="https://priestleyconstruction.co.uk/project/james-st-apartments/">
                <img
                  src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2018/08/11145301/Leeds-House-41-700x458.jpg"
                  className="c-Image o-M15 c-Image-full-width"
                  loading="lazy"
                  width="700"
                  height="458"
                  title="Leeds-House&#8211;41"
                  alt=""
                />
                <h3 className="c-Title">James St Apartments</h3>
                <span className="o-M10 c-Title c-ImageCards_Subtitle u-ColorBrand">
                  Bradford
                </span>
              </a>
            </li>
          </ul>
          <nav className="c-Pagination">
            <span
              aria-current="page"
              className="c-Btn c-Pagination_Link c-Pagination_Link-current"
            >
              1
            </span>
            <a
              className="c-Btn c-Pagination_Link"
              href="https://priestleyconstruction.co.uk/projects/sector/residential/page/2/"
            >
              2
            </a>
            <a
              className="c-Btn c-Pagination_Link c-Pagination_Link-next"
              href="https://priestleyconstruction.co.uk/projects/sector/residential/page/2/"
            >
              &rarr;
            </a>
            <span className="c-Pagination_Count">
              2 pages with 19 developments
            </span>
          </nav>
        </div>
      </div>
      <div className="o-Section o-Section-bg-grey-lighter o-Section-no-switch">
        <div className="o-Center c-Newsletter">
          <h2 className="o-M30 c-Title u-TextAlignCenter">Hear more from us</h2>
          <p className="o-M30 u-TextAlignCenter">
            Get the inside scoop on our VIP launches, early bird offers, latest
            projects, company news and more.
          </p>
        </div>
      </div>
    </main>
  );
};

export default Recidential;
