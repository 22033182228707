import React from "react";
import { SiteDataProps } from "../../models/LayoutModels";
import { IMAGE_URL } from "../../settings/config";
import { Link } from "react-router-dom";
interface FooterProps {
  siteData: SiteDataProps;
}

const Footer = (props: FooterProps) => {
  return (
    <footer className="c-Footer">
      <div className="o-Container">
        <div className="o-Flex c-Footer_Top">
          <div className="o-Flex o-Flex-center c-Social">
            <h3 className="c-Social_Title">Let's connect</h3>
            <ul className="c-Social_List">
              <li className="c-Social_Item">
                <a
                  href="https://www.instagram.com/priestleyconstruction/?hl=en"
                  className="c-Social_Link"
                >
                  <span className="c-Icon c-Icon-social-instagram c-Social_Icon"></span>
                  <span className="u-VisuallyHidden">Instagram</span>
                </a>
              </li>
              <li className="c-Social_Item">
                <a
                  href="https://www.facebook.com/priestleyconstructionltd/"
                  className="c-Social_Link"
                >
                  <span className="c-Icon c-Icon-social-facebook c-Social_Icon"></span>
                  <span className="u-VisuallyHidden">Facebook</span>
                </a>
              </li>
              <li className="c-Social_Item">
                <a
                  href="https://www.linkedin.com/company/priestley-construction/"
                  className="c-Social_Link"
                >
                  <span className="c-Icon c-Icon-social-linkedin c-Social_Icon"></span>
                  <span className="u-VisuallyHidden">LinkedIn</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <nav
          className="o-M30 o-Flex c-FooterNav"
          //   itemscope=""
          //   itemtype="http://schema.org/SiteNavigationElement"
        >
          <Link to="/" className="c-Logo" title={props.siteData.app_name}>
            <img
              src={`${IMAGE_URL}/${props.siteData.logo}`}
              className="c-Image js-Logo"
              height={60}
              width={60}
              alt={props.siteData.app_name}
            />
          </Link>
          {/* <div className="c-Collapsible c-FooterNav_Block js-Collapsible">
            <h4 className="o-Flex o-Flex-center c-Collapsible_Btn c-FooterNav_Title js-CollapsibleBtn">
              What We Do
              <span className="o-Flex_Push c-Icon c-Icon-arrow-right c-FooterNav_Icon"></span>
            </h4>
            <ul className="c-Collapsible_Body c-FooterNav_List">
              <li className="c-Nav_Item c-Nav_Item-0">
                <a
                  title="Current Projects"
                  //   itemprop="url"
                  href="https://priestleyconstruction.co.uk/projects/status/current/"
                  className="c-Nav_Link c-Nav_Link-0"
                >
                  <span
                    //   itemprop="name"
                    className="c-Nav_Title"
                  >
                    Current Projects
                  </span>
                </a>
              </li>
              <li className="c-Nav_Item c-Nav_Item-0">
                <a
                  title="Completed Projects"
                  //   itemprop="url"
                  href="https://priestleyconstruction.co.uk/projects/status/completed/"
                  className="c-Nav_Link c-Nav_Link-0"
                >
                  <span
                    //   itemprop="name"
                    className="c-Nav_Title"
                  >
                    Completed Projects
                  </span>
                </a>
              </li>
              <li className="c-Nav_Item c-Nav_Item-0">
                <a
                  title="Residential"
                  //   itemprop="url"
                  href="https://priestleyconstruction.co.uk/projects/sector/residential/"
                  className="c-Nav_Link c-Nav_Link-0"
                >
                  <span
                    //   itemprop="name"
                    className="c-Nav_Title"
                  >
                    Residential
                  </span>
                </a>
              </li>
              <li className="c-Nav_Item c-Nav_Item-0">
                <a
                  title="Commercial"
                  //   itemprop="url"
                  href="https://priestleyconstruction.co.uk/projects/sector/commercial/"
                  className="c-Nav_Link c-Nav_Link-0"
                >
                  <span
                    //   itemprop="name"
                    className="c-Nav_Title"
                  >
                    Commercial
                  </span>
                </a>
              </li>
              <li className="c-Nav_Item c-Nav_Item-0">
                <a
                  title="Student Accommodation"
                  //   itemprop="url"
                  href="https://priestleyconstruction.co.uk/projects/sector/student-accommodation/"
                  className="c-Nav_Link c-Nav_Link-0"
                >
                  <span
                    //   itemprop="name"
                    className="c-Nav_Title"
                  >
                    Student Accommodation
                  </span>
                </a>
              </li>
              <li className="c-Nav_Item c-Nav_Item-0">
                <a
                  title="Design"
                  //   itemprop="url"
                  href="https://priestleyconstruction.co.uk/design/"
                  className="c-Nav_Link c-Nav_Link-0"
                >
                  <span
                    //   itemprop="name"
                    className="c-Nav_Title"
                  >
                    Design
                  </span>
                </a>
              </li>
              <li className="c-Nav_Item c-Nav_Item-0">
                <a
                  title="Construction"
                  //   itemprop="url"
                  href="https://priestleyconstruction.co.uk/construction/"
                  className="c-Nav_Link c-Nav_Link-0"
                >
                  <span
                    //   itemprop="name"
                    className="c-Nav_Title"
                  >
                    Construction
                  </span>
                </a>
              </li>
            </ul>
          </div> */}
          <div className="c-Collapsible c-FooterNav_Block js-Collapsible">
            <h4 className="o-Flex o-Flex-center c-Collapsible_Btn c-FooterNav_Title js-CollapsibleBtn">
              Who We Are
              <span className="o-Flex_Push c-Icon c-Icon-arrow-right c-FooterNav_Icon"></span>
            </h4>
            <ul className="c-Collapsible_Body c-FooterNav_List">
              {/* <li className="c-Nav_Item c-Nav_Item-0">
                <a
                  title="Our Values"
                  //   itemprop="url"
                  href="https://priestleyconstruction.co.uk/our-values/"
                  className="c-Nav_Link c-Nav_Link-0"
                >
                  <span
                    //   itemprop="name"
                    className="c-Nav_Title"
                  >
                    Our Values
                  </span>
                </a>
              </li> */}
              {/* <li className="c-Nav_Item c-Nav_Item-0">
                <a
                  title="News &#038; Updates"
                  //   itemprop="url"
                  href="https://priestleyconstruction.co.uk/news-and-updates.html"
                  className="c-Nav_Link c-Nav_Link-0"
                >
                  <span
                    //   itemprop="name"
                    className="c-Nav_Title"
                  >
                    News &#038; Updates
                  </span>
                </a>
              </li> */}
              <li className="c-Nav_Item c-Nav_Item-0">
                <Link
                  title="Team Members"
                  //   itemprop="url"
                  to="/our-team"
                  className="c-Nav_Link c-Nav_Link-0"
                >
                  <span
                    //   itemprop="name"
                    className="c-Nav_Title"
                  >
                    Team Members
                  </span>
                </Link>
              </li>
              <li className="c-Nav_Item c-Nav_Item-0">
                <Link
                  title="Contact Us"
                  //   itemprop="url"
                  to="/contact-us/"
                  className="c-Nav_Link c-Nav_Link-0"
                >
                  <span
                    //   itemprop="name"
                    className="c-Nav_Title"
                  >
                    Contact Us
                  </span>
                </Link>
              </li>
            </ul>
          </div>
          {/* <div className="c-Collapsible c-FooterNav_Block js-Collapsible">
            <h4 className="o-Flex o-Flex-center c-Collapsible_Btn c-FooterNav_Title js-CollapsibleBtn">
              Legal
              <span className="o-Flex_Push c-Icon c-Icon-arrow-right c-FooterNav_Icon"></span>
            </h4>
            <ul className="c-Collapsible_Body c-FooterNav_List">
              <li className="c-Nav_Item c-Nav_Item-0">
                <a
                  title="Privacy Policy"
                  //   itemprop="url"
                  href="https://priestleyconstruction.co.uk/privacy-policy/"
                  className="c-Nav_Link c-Nav_Link-0"
                >
                  <span
                    //   itemprop="name"
                    className="c-Nav_Title"
                  >
                    Privacy Policy
                  </span>
                </a>
              </li>
              <li className="c-Nav_Item c-Nav_Item-0">
                <a
                  title="Website Terms of Use"
                  //   itemprop="url"
                  href="https://priestleyconstruction.co.uk/website-terms-of-use/"
                  className="c-Nav_Link c-Nav_Link-0"
                >
                  <span
                    //   itemprop="name"
                    className="c-Nav_Title"
                  >
                    Website Terms of Use
                  </span>
                </a>
              </li>
              <li className="c-Nav_Item c-Nav_Item-0">
                <a
                  title="Cookie Policy"
                  //   itemprop="url"
                  href="https://priestleyconstruction.co.uk/cookie-policy/"
                  className="c-Nav_Link c-Nav_Link-0"
                >
                  <span
                    //   itemprop="name"
                    className="c-Nav_Title"
                  >
                    Cookie Policy
                  </span>
                </a>
              </li>
              <li className="c-Nav_Item c-Nav_Item-0">
                <a
                  title="Modern Anti-Slavery Statement"
                  target="_blank"
                  //   itemprop="url"
                  href="/"
                  className="c-Nav_Link c-Nav_Link-0"
                >
                  <span
                    //   itemprop="name"
                    className="c-Nav_Title"
                  >
                    Modern Anti-Slavery Statement
                  </span>
                </a>
              </li>
            </ul>
          </div> */}
        </nav>
        <div className="o-Flex o-Flex-center">
          <span className="o-Flex_Grow c-Legal_Copyright">
            &copy; 2023
            <Link to="/">Thrive Consultancy Ltd</Link>.
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
