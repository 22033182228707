import { useEffect, useState } from "react";
import { fetchTeamsData } from "../../APIS/ourTeam";
import { TeamsDataProps } from "../../models/TeamsModel";
import { IMAGE_URL } from "../../settings/config";


const OurTeam = () => {
  const [data, setData] = useState<TeamsDataProps[]>([]);
  const [overlayOpen, setOverlayOpen] = useState<boolean>(false);

  // Fetch data when the component mounts
  async function fetchData() {
    try {
      const result = await fetchTeamsData();
      setData(result?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  // useEffect block
  useEffect(() => {
    fetchData();
  }, []);

  console.log(data);
  
  return (
    <main className="c-Page_Main">
      <header className="o-Section o-Section-bg-grey-lighter c-PageBanner">
        <div className="o-Container">
          <h1 className="c-Title c-PageBanner_Title">Our Senior Team</h1>
          <h2 className="o-P20 c-Title c-PageBanner_Subtitle">
            <p>
              We thrive on great people. Our team come from all walks of life,
              yet each bring a particular skill set – from architecture and
              design to consultancy. But we all put you as our number one
              priority, delivering your vision without compromise. We
              can&#8217;t list all of our great team, but meet the office-based
              driving force behind our business.
              <a href="https://priestleyconstruction.co.uk/careers/">
                Join us.
              </a>
            </p>
          </h2>
        </div>
      </header>
      <div className="o-Section o-Section-bg-grey-lighter">
        <div className="o-Container">
          <ul className="o-Flex o-Flex-wrap o-Flex-gutters c-ImageCards">
            {data?.map((item) => (
              <li className="o-Flex_Block c-ImageCards_Item" key={item?.id}>
                <div
                  className="c-ImageCards_Link js-OverlayTarget"
                  data-overlay-target="90"
                  onClick={() => setOverlayOpen(true)}
                >
                  <img
                    src={`${IMAGE_URL}/${item?.image}`}
                    className="c-Image o-M15 c-Image-full-width"
                    loading="lazy"
                    width="700"
                    height="458"
                    title="Nathan"
                    alt=""
                  />
                  <h3 className="c-Title">{item?.name}</h3>
                  <span className="c-Title c-ImageCards_Subtitle u-ColorBrand">
                    {item?.designation}
                  </span>
                </div>
                <div
                  className="c-Overlay js-Overlay"
                  data-overlay="90"
                  style={
                    overlayOpen
                      ? { visibility: "visible", opacity: 1 }
                      : { visibility: "hidden", opacity: 0 }
                  }
                >
                  <div className="c-Overlay_Scroll">
                    <div className="c-Overlay_Inner">
                      <h3 className="o-M4 c-Title c-Overlay_Title">
                        {item?.name}
                      </h3>
                      <span className="o-M20 c-Title u-ColorBrand u-Block">
                        {item?.designation}
                      </span>
                      <div className="c-Content">
                        {/* <p>
                          Nathan is the Founder and CEO of Priestley
                          Construction as well as our sister companies,
                          Priestley Homes, Priestley&#8217;s and 30+
                          subsidiaries contained within the group. After working
                          in the property industry in agency and then as a
                          consultant, Nathan decided to start Priestley
                          Construction following the successful launch of
                          Priestley&#8217;s, now the successful estate and
                          lettings agency within the group. Priestley
                          Construction has experienced unprecedented success and
                          is now a main contractor with clients based across the
                          globe. Delivering projects from office refurbishments
                          to huge listed mill conversions, the company is now a
                          leader in this sector with Nathan directing the
                          company from a standing start, to an award winning
                          conversion and refurbishment specialist.
                        </p> */}
                        <blockquote>
                          <p>
                            &#8216;I am very fortunate that my hobby is my job.
                            I am a self-confessed property and business geek. I
                            genuinely love coming to work every day, even on the
                            days when everything that could go wrong, does go
                            wrong. When I do find time to relax, I enjoy road
                            cycling, weekends away and the odd tipple shared
                            with close friends and family. Oh and food&#8230;
                            everything food&#8217;
                          </p>
                        </blockquote>
                      </div>
                    </div>
                    <button className="c-Overlay_Close js-OverlayClose" onClick={() => setOverlayOpen(false)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        width={40}
                        hanging={40}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </li>
            ))}

            {/* <li className="o-Flex_Block c-ImageCards_Item">
              <a
                className="c-ImageCards_Link js-OverlayTarget"
                data-overlay-target="205"
                href="/"
              >
                <img
                  src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2018/04/Derry-700x458.jpg"
                  className="c-Image o-M15 c-Image-full-width"
                  loading="lazy"
                  width="700"
                  height="458"
                  title="Derry"
                  alt=""
                />
                <h3 className="c-Title">Derry McCulloch</h3>
                <span className="c-Title c-ImageCards_Subtitle u-ColorBrand">
                  PA to CEO
                </span>
              </a>
              <div className="c-Overlay js-Overlay" data-overlay="205">
                <div className="c-Overlay_Scroll">
                  <div className="c-Overlay_Inner">
                    <h3 className="o-M4 c-Title c-Overlay_Title">
                      Derry McCulloch
                    </h3>
                    <span className="o-M20 c-Title u-ColorBrand u-Block">
                      PA to CEO
                    </span>
                    <div className="c-Content">
                      <p>
                        Derry joined the Priestley Group in April 2010 as Head
                        of Maintenance. She continued to grow with the company,
                        taking on various roles before progressing to her
                        current role as PA to CEO and head of after sales. Her
                        experience in sales and customer services proved to be
                        invaluable to the Group. Derry is involved with all 40+
                        companies under the Group umbrella.
                      </p>
                      <blockquote>
                        <p>
                          &#8216;As I’m a mother of three, in my spare time I
                          like to cook and look after my two grandchildren
                          Florence and Felix. I love to take the children
                          shopping and cooking them a meal before snuggling up
                          on the sofa to watch a film. I also love to dine out
                          with my family and catch the latest movie at the
                          cinema.&#8217;
                        </p>
                      </blockquote>
                    </div>
                  </div>
                  <button className="c-Overlay_Close js-OverlayClose">
                    <span className="c-Icon c-Icon-close"></span>
                  </button>
                </div>
              </div>
            </li>
            <li className="o-Flex_Block c-ImageCards_Item">
              <a
                className="c-ImageCards_Link js-OverlayTarget"
                data-overlay-target="207"
                href="/"
              >
                <img
                  src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2018/04/Mike-700x458.jpg"
                  className="c-Image o-M15 c-Image-full-width"
                  loading="lazy"
                  width="700"
                  height="458"
                  title="Mike"
                  alt=""
                />
                <h3 className="c-Title">Mike Gregory</h3>
                <span className="c-Title c-ImageCards_Subtitle u-ColorBrand">
                  Head of Finance
                </span>
              </a>
              <div className="c-Overlay js-Overlay" data-overlay="207">
                <div className="c-Overlay_Scroll">
                  <div className="c-Overlay_Inner">
                    <h3 className="o-M4 c-Title c-Overlay_Title">
                      Mike Gregory
                    </h3>
                    <span className="o-M20 c-Title u-ColorBrand u-Block">
                      Head of Finance
                    </span>
                    <div className="c-Content">
                      <p>
                        Mike is the Head of Finance based in the Leeds office
                        and has been with the Priestley Group since July 2014.
                        Having worked in finance for over 19 years advising
                        SME’s and large multinational corporations along with
                        the UK and foreign governments, Mike brings a wealth of
                        experience to the Accounts team. After working for
                        PricewaterhouseCoopers (PwC) and KPMG for 15 years, Mike
                        utilises his skills across the Priestley Group and
                        enjoys the challenges a fast-growing group presents.
                      </p>
                      <blockquote>
                        <p>
                          &#8216;When I’m not working, I enjoy spending time
                          with my wife and young children, watching films and
                          making full use of our annual passes to Chester
                          Zoo.&#8217;
                        </p>
                      </blockquote>
                    </div>
                  </div>
                  <button className="c-Overlay_Close js-OverlayClose">
                    <span className="c-Icon c-Icon-close"></span>
                  </button>
                </div>
              </div>
            </li>
            <li className="o-Flex_Block c-ImageCards_Item">
              <a
                className="c-ImageCards_Link js-OverlayTarget"
                data-overlay-target="1140"
                href="/"
              >
                <img
                  src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2018/04/Bob-700x458.jpg"
                  className="c-Image o-M15 c-Image-full-width"
                  loading="lazy"
                  width="700"
                  height="458"
                  title="Bob"
                  alt=""
                />
                <h3 className="c-Title">Bob Priestley</h3>
                <span className="c-Title c-ImageCards_Subtitle u-ColorBrand">
                  Head of Construction
                </span>
              </a>
              <div className="c-Overlay js-Overlay" data-overlay="1140">
                <div className="c-Overlay_Scroll">
                  <div className="c-Overlay_Inner">
                    <h3 className="o-M4 c-Title c-Overlay_Title">
                      Bob Priestley
                    </h3>
                    <span className="o-M20 c-Title u-ColorBrand u-Block">
                      Head of Construction
                    </span>
                    <div className="c-Content">
                      <p>
                        With 36 years of experience throughout the construction
                        industry, Bob’s knowledge and leadership skills are
                        valuable additions to Priestley Construction. Bob’s main
                        skills lie within design management, QS, contract
                        management, subcontracting, and construction safety.
                      </p>
                    </div>
                  </div>
                  <button className="c-Overlay_Close js-OverlayClose">
                    <span className="c-Icon c-Icon-close"></span>
                  </button>
                </div>
              </div>
            </li>
            <li className="o-Flex_Block c-ImageCards_Item">
              <a
                className="c-ImageCards_Link js-OverlayTarget"
                data-overlay-target="1142"
                href="/"
              >
                <img
                  src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2018/04/Patrick-700x458.jpg"
                  className="c-Image o-M15 c-Image-full-width"
                  loading="lazy"
                  width="700"
                  height="458"
                  title="Patrick"
                  alt=""
                />
                <h3 className="c-Title">Patrick Rufus</h3>
                <span className="c-Title c-ImageCards_Subtitle u-ColorBrand">
                  Managing Estimator
                </span>
              </a>
              <div className="c-Overlay js-Overlay" data-overlay="1142">
                <div className="c-Overlay_Scroll">
                  <div className="c-Overlay_Inner">
                    <h3 className="o-M4 c-Title c-Overlay_Title">
                      Patrick Rufus
                    </h3>
                    <span className="o-M20 c-Title u-ColorBrand u-Block">
                      Managing Estimator
                    </span>
                    <div className="c-Content">
                      <p>
                        Patrick has been with Priestley since 2020 as a Senior
                        Estimator and has over 30 years of experience in
                        construction site management, contracts management,
                        estimating as well as a strong focus on heritage
                        construction projects.
                      </p>
                    </div>
                  </div>
                  <button className="c-Overlay_Close js-OverlayClose">
                    <span className="c-Icon c-Icon-close"></span>
                  </button>
                </div>
              </div>
            </li>
            <li className="o-Flex_Block c-ImageCards_Item">
              <a
                className="c-ImageCards_Link js-OverlayTarget"
                data-overlay-target="729"
                href="/"
              >
                <img
                  src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2019/02/18155859/Laura-Crossley-700x458.jpg"
                  className="c-Image o-M15 c-Image-full-width"
                  loading="lazy"
                  width="700"
                  height="458"
                  title="Laura Crossley"
                  alt=""
                />
                <h3 className="c-Title">Laura Crossley</h3>
                <span className="c-Title c-ImageCards_Subtitle u-ColorBrand">
                  Office &amp; Building Manager
                </span>
              </a>
              <div className="c-Overlay js-Overlay" data-overlay="729">
                <div className="c-Overlay_Scroll">
                  <div className="c-Overlay_Inner">
                    <h3 className="o-M4 c-Title c-Overlay_Title">
                      Laura Crossley
                    </h3>
                    <span className="o-M20 c-Title u-ColorBrand u-Block">
                      Office &amp; Building Manager
                    </span>
                    <div className="c-Content">
                      <p>
                        Laura joined the group in 2017 as our front of house
                        receptionist. Having excelled in all aspects of her
                        work, Laura was promoted in 2018 to Office manager for
                        the group. Laura looks after all things HR and the day
                        to day running of the office. A very popular member of
                        the teams in all of the Priestley Group companies with
                        many more years to come.
                      </p>
                      <blockquote>
                        <p>
                          In my spare time, I am a key team member of the
                          Holmfirth Hotshots Netball squad. I enjoy going on
                          holiday to new destinations, eating out and most of
                          all, planning and hosting my annual Halloween party.
                        </p>
                      </blockquote>
                    </div>
                  </div>
                  <button className="c-Overlay_Close js-OverlayClose">
                    <span className="c-Icon c-Icon-close"></span>
                  </button>
                </div>
              </div>
            </li>
            <li className="o-Flex_Block c-ImageCards_Item">
              <a
                className="c-ImageCards_Link js-OverlayTarget"
                data-overlay-target="1369"
                href="/"
              >
                <img
                  src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2018/04/Paul-700x458.jpg"
                  className="c-Image o-M15 c-Image-full-width"
                  loading="lazy"
                  width="700"
                  height="458"
                  title="Paul"
                  alt=""
                />
                <h3 className="c-Title">Paul Taylor</h3>
                <span className="c-Title c-ImageCards_Subtitle u-ColorBrand">
                  Head of Stakeholder Developments
                </span>
              </a>
              <div className="c-Overlay js-Overlay" data-overlay="1369">
                <div className="c-Overlay_Scroll">
                  <div className="c-Overlay_Inner">
                    <h3 className="o-M4 c-Title c-Overlay_Title">
                      Paul Taylor
                    </h3>
                    <span className="o-M20 c-Title u-ColorBrand u-Block">
                      Head of Stakeholder Developments
                    </span>
                    <div className="c-Content">
                      <p>
                        Paul started his career as a quantity surveyor, working
                        on both the client and contractor side, before marking
                        his move into Business Development. Paul enjoys the
                        spontaneity of the Business Development position &#8211;
                        one phone call and your carefully laid plans go out of
                        the window.
                      </p>
                      <blockquote>
                        <p>
                          I love the fantastic buildings the United Kingdom has
                          to offer, and the top of my list is St Pancras Station
                          in London&#8230; I’m not sure we could build that
                          today.
                        </p>
                        <p>
                          Outside of work I’m a passionate supporter of Leeds
                          United, I love reading modern history and I’m an
                          enthusiastic, but rather amateurish, DIYer.
                        </p>
                      </blockquote>
                    </div>
                  </div>
                  <button className="c-Overlay_Close js-OverlayClose">
                    <span className="c-Icon c-Icon-close"></span>
                  </button>
                </div>
              </div>
            </li>
            <li className="o-Flex_Block c-ImageCards_Item">
              <a
                href="/"
                className="c-ImageCards_Link js-OverlayTarget"
                data-overlay-target="1387"
              >
                <img
                  src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2022/11/Darren-700x458.jpg"
                  className="c-Image o-M15 c-Image-full-width"
                  loading="lazy"
                  width="700"
                  height="458"
                  title="Darren"
                  alt=""
                />
                <h3 className="c-Title">Darren Commons</h3>
                <span className="c-Title c-ImageCards_Subtitle u-ColorBrand">
                  Contracts Manager
                </span>
              </a>
              <div className="c-Overlay js-Overlay" data-overlay="1387">
                <div className="c-Overlay_Scroll">
                  <div className="c-Overlay_Inner">
                    <h3 className="o-M4 c-Title c-Overlay_Title">
                      Darren Commons
                    </h3>
                    <span className="o-M20 c-Title u-ColorBrand u-Block">
                      Contracts Manager
                    </span>
                    <div className="c-Content">
                      <p>
                        Darren has over 25 years’ experience within the
                        construction industry, starting with working on roofs
                        with his grandfather. He has worked on the refurbishment
                        of many old buildings, from the old Whitbread family
                        home in Essendon, the former three counties mental
                        hospital near Letchworth Garden city to the former
                        Terry’s chocolate factory which won the premier
                        guarantee national award for best residential property
                        of the year about 6 years ago.
                      </p>
                      <blockquote>
                        <p>
                          I enjoy construction but my passion is renovating old
                          buildings that are no longer fit for purpose and
                          transforming them into something new and vibrant to
                          keep up with modern times while still maintaining the
                          history they have.
                        </p>
                        <p>
                          Outside of work I enjoy the odd game of golf and
                          taking my dog for walks.
                        </p>
                      </blockquote>
                    </div>
                  </div>
                  <button className="c-Overlay_Close js-OverlayClose">
                    <span className="c-Icon c-Icon-close"></span>
                  </button>
                </div>
              </div>
            </li> */}
          </ul>
        </div>
      </div>
      <div className="o-Section o-Section-bg-grey-lighter o-Section-no-switch">
        <div className="o-Center c-Newsletter">
          <h2 className="o-M30 c-Title u-TextAlignCenter">Hear more from us</h2>
          <p className="o-M30 u-TextAlignCenter">
            Get the inside scoop on our VIP launches, early bird offers, latest
            projects, company news and more.
          </p>
        </div>
      </div>
    </main>
  );
}

export default OurTeam