import { createBrowserRouter } from "react-router-dom";
import Layout from "./components/Layout/Layout";
import Home from "./pages/home";
import PastPresent from "./pages/pastPresent";
import OurValues from "./pages/ourValues";
import OurTeam from "./pages/ourTeam";
import Career from "./pages/careers";
import Construction from "./pages/construction";
import Design from "./pages/design";
import Projects from "./pages/projects";
import CompletedProject from "./pages/completedProject";
import Recidential from "./pages/recidential";
import Commercial from "./pages/commercial";
import StudentAccommodation from "./pages/studentAccommodation";
import News from "./pages/news";
import ContactUs from "./pages/contactus";

export const RouteApp = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/past-present-future",
        element: <PastPresent />,
      },
      {
        path: "/our-values",
        element: <OurValues />,
      },
      {
        path: "/our-team",
        element: <OurTeam />,
      },
      {
        path: "/careers",
        element: <Career />,
      },
      {
        path: "/construction",
        element: <Construction />,
      },
      {
        path: "/design",
        element: <Design />,
      },
      {
        path: "/projects",
        element: <Projects />,
      },
      {
        path: "/projects-completed",
        element: <CompletedProject />,
      },
      {
        path: "/projects-recidential",
        element: <Recidential />,
      },
      {
        path: "/projects-commercial",
        element: <Commercial />,
      },
      {
        path: "/projects-student-accommodation",
        element: <StudentAccommodation />,
      },
      {
        path: "/news",
        element: <News />,
      },
      {
        path: "/contact-us",
        element: <ContactUs />,
      },
    ],
  },
]);
