import React from "react";

const News = () => {
  return (
    <main className="c-Page_Main">
      <header className="o-Section o-Section-bg-grey-lighter c-PageBanner">
        <div className="o-Container o-Flex o-Flex-center o-Flex-wrap">
          <h1 className="c-Title c-PageBanner_Title">
            News &amp; <br />
            Project Updates
          </h1>
          <ul className="c-PageBanner_Social">
            <li className="c-Social_Item">
              <a
                href="https://www.instagram.com/priestleyconstruction/?hl=en"
                className="c-Social_Link"
              >
                <span className="c-Icon c-Icon-social-instagram-dark c-Social_Icon"></span>
                <span className="u-VisuallyHidden">Instagram</span>
              </a>
            </li>
            <li className="c-Social_Item">
              <a
                href="https://www.facebook.com/priestleyconstructionltd/"
                className="c-Social_Link"
              >
                <span className="c-Icon c-Icon-social-facebook-dark c-Social_Icon"></span>
                <span className="u-VisuallyHidden">Facebook</span>
              </a>
            </li>
            <li className="c-Social_Item">
              <a
                href="https://www.linkedin.com/company/priestley-construction/"
                className="c-Social_Link"
              >
                <span className="c-Icon c-Icon-social-linkedin-dark c-Social_Icon"></span>
                <span className="u-VisuallyHidden">LinkedIn</span>
              </a>
            </li>
          </ul>
        </div>
      </header>
      <div className="o-Section o-Section-bg-grey-lighter">
        <div className="o-Container">
          <ol className="o-Flex o-Flex-wrap c-Cards">
            <li className="c-Cards_Item">
              <a
                href="https://priestleyconstruction.co.uk/project/marygate-house/"
                className="c-Cards_ItemInner"
              >
                <div className="c-Cards_Image">
                  <img
                    src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2023/12/1-1-700x458.jpg"
                    className="c-Image c-Image-full-width"
                    loading="lazy"
                    width="700"
                    height="458"
                    title="1"
                    alt=""
                  />
                </div>
                <div className="c-Content c-Cards_Content">
                  <div className="o-Flex o-Flex-center c-Cards_Meta">
                    <span className="c-Title c-Cards_Type c-Cards_Type-alt">
                      Project Update
                    </span>
                    <span className="c-Title c-Cards_Time">1 week ago</span>
                  </div>
                  <h5>Site progress update, Novemeber 2023 – Marygate House</h5>
                  <p>
                    Progress update at our Wakefield city centre project.
                    Enabling works are on-going and the windows have been
                    removed from all [&hellip;]
                  </p>
                  <span className="c-Title c-Cards_Btn u-Underline u-Underline-push">
                    View project
                  </span>
                </div>
              </a>
            </li>
            <li className="c-Cards_Item">
              <a
                href="https://priestleyconstruction.co.uk/project/luxury-apartments-coming-to-great-george-street-leeds/"
                className="c-Cards_ItemInner"
              >
                <div className="c-Cards_Image">
                  <img
                    src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2023/12/AJS4327-700x458.jpg"
                    className="c-Image c-Image-full-width"
                    loading="lazy"
                    width="700"
                    height="458"
                    title="_AJS4327"
                    alt=""
                  />
                </div>
                <div className="c-Content c-Cards_Content">
                  <div className="o-Flex o-Flex-center c-Cards_Meta">
                    <span className="c-Title c-Cards_Type c-Cards_Type-alt">
                      Project Update
                    </span>
                    <span className="c-Title c-Cards_Time">1 week ago</span>
                  </div>
                  <h5>
                    Site progress update, November 2023 – Great George Street
                  </h5>
                  <p>
                    Progress update, we have seen the completion of the stunning
                    duplex show home apartment leading to a very successful show
                    [&hellip;]
                  </p>
                  <span className="c-Title c-Cards_Btn u-Underline u-Underline-push">
                    View project
                  </span>
                </div>
              </a>
            </li>
            <li className="c-Cards_Item">
              <a
                href="https://priestleyconstruction.co.uk/project/conditioning-house-phase-4/"
                className="c-Cards_ItemInner"
              >
                <div className="c-Cards_Image">
                  <img
                    src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2023/12/6-700x458.jpg"
                    className="c-Image c-Image-full-width"
                    loading="lazy"
                    width="700"
                    height="458"
                    title="6"
                    alt=""
                  />
                </div>
                <div className="c-Content c-Cards_Content">
                  <div className="o-Flex o-Flex-center c-Cards_Meta">
                    <span className="c-Title c-Cards_Type c-Cards_Type-alt">
                      Project Update
                    </span>
                    <span className="c-Title c-Cards_Time">1 week ago</span>
                  </div>
                  <h5>
                    Site progress update, December 2023 – Conditioning House,
                    Serviced Offices
                  </h5>
                  <p>
                    Progress update at our Serviced Office scheme at the Award –
                    Winning Conditioning House, Bradford. We are converting this
                    lower [&hellip;]
                  </p>
                  <span className="c-Title c-Cards_Btn u-Underline u-Underline-push">
                    View project
                  </span>
                </div>
              </a>
            </li>
            <li className="c-Cards_Item">
              <a
                href="https://priestleyconstruction.co.uk/news/award-win-at-the-prestigious-international-property-awards/"
                className="c-Cards_ItemInner"
              >
                <div className="c-Cards_Image">
                  <img
                    src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2023/11/priestley-group-uk-property-awards-2023-03-700x458.jpg"
                    className="c-Image c-Image-full-width"
                    loading="lazy"
                    width="700"
                    height="458"
                    title="priestley-group-uk-property-awards-2023-03"
                    alt=""
                  />
                </div>
                <div className="c-Content c-Cards_Content">
                  <div className="o-Flex o-Flex-center c-Cards_Meta">
                    <span className="c-Title c-Cards_Type">News</span>
                    <span className="c-Title c-Cards_Time">3 weeks ago</span>
                  </div>
                  <h5>
                    Award Win At The Prestigious International Property Awards
                  </h5>
                  <p>
                    We have won an award at the prestigious International
                    Property Awards. We won the award for best new small hotel
                    [&hellip;]
                  </p>
                  <span className="c-Title c-Cards_Btn u-Underline u-Underline-push">
                    Read more
                  </span>
                </div>
              </a>
            </li>
            <li className="c-Cards_Item">
              <a
                href="https://priestleyconstruction.co.uk/project/marygate-house/"
                className="c-Cards_ItemInner"
              >
                <div className="c-Cards_Image">
                  <img
                    src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2023/11/9-700x458.jpg"
                    className="c-Image c-Image-full-width"
                    loading="lazy"
                    width="700"
                    height="458"
                    title="9"
                    alt=""
                  />
                </div>
                <div className="c-Content c-Cards_Content">
                  <div className="o-Flex o-Flex-center c-Cards_Meta">
                    <span className="c-Title c-Cards_Type c-Cards_Type-alt">
                      Project Update
                    </span>
                    <span className="c-Title c-Cards_Time">1 month ago</span>
                  </div>
                  <h5>Site progress update, October 2023 – Marygate House</h5>
                  <p>
                    Progress update at our Wakefield city centre project for
                    repeat clients @deuce_hotels We will be converting this
                    building into a [&hellip;]
                  </p>
                  <span className="c-Title c-Cards_Btn u-Underline u-Underline-push">
                    View project
                  </span>
                </div>
              </a>
            </li>
            <li className="c-Cards_Item">
              <a
                href="https://priestleyconstruction.co.uk/project/luxury-apartments-coming-to-great-george-street-leeds/"
                className="c-Cards_ItemInner"
              >
                <div className="c-Cards_Image">
                  <img
                    src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2023/11/Site-progress-update-October-2023-Great-George-Street-558x458.jpg"
                    className="c-Image c-Image-full-width"
                    loading="lazy"
                    width="558"
                    height="458"
                    title="Site-progress-update-October-2023-Great-George-Street"
                    alt=""
                  />
                </div>
                <div className="c-Content c-Cards_Content">
                  <div className="o-Flex o-Flex-center c-Cards_Meta">
                    <span className="c-Title c-Cards_Type c-Cards_Type-alt">
                      Project Update
                    </span>
                    <span className="c-Title c-Cards_Time">1 month ago</span>
                  </div>
                  <h5>
                    Site progress update, October 2023 – Great George Street
                  </h5>
                  <p>
                    Progress update at Leeds Most Exclusive Address, we have
                    seen the concrete poured on the new fifth deck and after
                    [&hellip;]
                  </p>
                  <span className="c-Title c-Cards_Btn u-Underline u-Underline-push">
                    View project
                  </span>
                </div>
              </a>
            </li>
            <li className="c-Cards_Item">
              <a
                href="https://priestleyconstruction.co.uk/news/priestley-construction-builds-on-senior-team-with-key-appointments/"
                className="c-Cards_ItemInner"
              >
                <div className="c-Cards_Image">
                  <img
                    src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2023/10/Bob-Priestley-and-Daniel-Wright-Priestley-Construction-700x458.jpg"
                    className="c-Image c-Image-full-width"
                    loading="lazy"
                    width="700"
                    height="458"
                    title="Bob-Priestley-and-Daniel-Wright-Priestley-Construction"
                    alt=""
                  />
                </div>
                <div className="c-Content c-Cards_Content">
                  <div className="o-Flex o-Flex-center c-Cards_Meta">
                    <span className="c-Title c-Cards_Type">News</span>
                    <span className="c-Title c-Cards_Time">2 months ago</span>
                  </div>
                  <h5>
                    Priestley Construction Builds On Senior Team With Key
                    Appointments
                  </h5>
                  <p>
                    We are thrilled to announce the promotions of both Bob
                    Priestley and Daniel Wright. Bob has been appointed to the
                    [&hellip;]
                  </p>
                  <span className="c-Title c-Cards_Btn u-Underline u-Underline-push">
                    Read more
                  </span>
                </div>
              </a>
            </li>
            <li className="c-Cards_Item">
              <a
                href="https://priestleyconstruction.co.uk/project/the-paddocks/"
                className="c-Cards_ItemInner"
              >
                <div className="c-Cards_Image">
                  <img
                    src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2023/10/the-paddocks-september-2023-update-591x458.jpg"
                    className="c-Image c-Image-full-width"
                    loading="lazy"
                    width="591"
                    height="458"
                    title="the-paddocks-september-2023-update"
                    alt=""
                  />
                </div>
                <div className="c-Content c-Cards_Content">
                  <div className="o-Flex o-Flex-center c-Cards_Meta">
                    <span className="c-Title c-Cards_Type c-Cards_Type-alt">
                      Project Update
                    </span>
                    <span className="c-Title c-Cards_Time">2 months ago</span>
                  </div>
                  <h5>
                    Site progress update, September 2023 – The Paddocks,
                    Knutsford
                  </h5>
                  <p>
                    Progress update at The Paddocks, Knutsford, Cheshire, for
                    clients IP Properties. We are nearing completion at our
                    stunning site in [&hellip;]
                  </p>
                  <span className="c-Title c-Cards_Btn u-Underline u-Underline-push">
                    View project
                  </span>
                </div>
              </a>
            </li>
            <li className="c-Cards_Item">
              <a
                href="https://priestleyconstruction.co.uk/project/luxury-apartments-coming-to-great-george-street-leeds/"
                className="c-Cards_ItemInner"
              >
                <div className="c-Cards_Image">
                  <img
                    src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2023/10/ggs-september-2023-update-523x458.jpg"
                    className="c-Image c-Image-full-width"
                    loading="lazy"
                    width="523"
                    height="458"
                    title="ggs-september-2023-update"
                    alt=""
                  />
                </div>
                <div className="c-Content c-Cards_Content">
                  <div className="o-Flex o-Flex-center c-Cards_Meta">
                    <span className="c-Title c-Cards_Type c-Cards_Type-alt">
                      Project Update
                    </span>
                    <span className="c-Title c-Cards_Time">2 months ago</span>
                  </div>
                  <h5>
                    Site progress update, September 2023 – Great George Street
                  </h5>
                  <p>
                    Progress update at Leeds Most Exclusive Address, 2 Great
                    George Street. We have achieved a key milestone for this
                    development [&hellip;]
                  </p>
                  <span className="c-Title c-Cards_Btn u-Underline u-Underline-push">
                    View project
                  </span>
                </div>
              </a>
            </li>
            <li className="c-Cards_Item">
              <a
                href="https://priestleyconstruction.co.uk/project/luxury-apartments-coming-to-great-george-street-leeds/"
                className="c-Cards_ItemInner"
              >
                <div className="c-Cards_Image">
                  <img
                    src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2023/10/ggs-august-2023-update-398x458.jpg"
                    className="c-Image c-Image-full-width"
                    loading="lazy"
                    width="398"
                    height="458"
                    title="ggs-august-2023-update"
                    alt=""
                  />
                </div>
                <div className="c-Content c-Cards_Content">
                  <div className="o-Flex o-Flex-center c-Cards_Meta">
                    <span className="c-Title c-Cards_Type c-Cards_Type-alt">
                      Project Update
                    </span>
                    <span className="c-Title c-Cards_Time">2 months ago</span>
                  </div>
                  <h5>
                    Site progress update, August 2023 – Great George Street
                  </h5>
                  <p>
                    Project update with Darren Commons, Contracts Manager and
                    Mark Hoyland, Senior Site Manager at Leeds Most Exclusive
                    Address. The ground [&hellip;]
                  </p>
                  <span className="c-Title c-Cards_Btn u-Underline u-Underline-push">
                    View project
                  </span>
                </div>
              </a>
            </li>
            <li className="c-Cards_Item">
              <a
                href="https://priestleyconstruction.co.uk/project/the-paddocks/"
                className="c-Cards_ItemInner"
              >
                <div className="c-Cards_Image">
                  <img
                    src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2023/08/1-1-700x458.jpg"
                    className="c-Image c-Image-full-width"
                    loading="lazy"
                    width="700"
                    height="458"
                    title="1"
                    alt=""
                  />
                </div>
                <div className="c-Content c-Cards_Content">
                  <div className="o-Flex o-Flex-center c-Cards_Meta">
                    <span className="c-Title c-Cards_Type c-Cards_Type-alt">
                      Project Update
                    </span>
                    <span className="c-Title c-Cards_Time">4 months ago</span>
                  </div>
                  <h5>Site progress update, July 2023 – The Paddocks</h5>
                  <p>
                    Progress update at The Paddocks for repeat clients IP
                    Properties. These bespoke grand designs are turning into
                    something very special. [&hellip;]
                  </p>
                  <span className="c-Title c-Cards_Btn u-Underline u-Underline-push">
                    View project
                  </span>
                </div>
              </a>
            </li>
            <li className="c-Cards_Item">
              <a
                href="https://priestleyconstruction.co.uk/project/luxury-apartments-coming-to-great-george-street-leeds/"
                className="c-Cards_ItemInner"
              >
                <div className="c-Cards_Image">
                  <img
                    src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2023/08/5-700x458.jpg"
                    className="c-Image c-Image-full-width"
                    loading="lazy"
                    width="700"
                    height="458"
                    title="5"
                    alt=""
                  />
                </div>
                <div className="c-Content c-Cards_Content">
                  <div className="o-Flex o-Flex-center c-Cards_Meta">
                    <span className="c-Title c-Cards_Type c-Cards_Type-alt">
                      Project Update
                    </span>
                    <span className="c-Title c-Cards_Time">4 months ago</span>
                  </div>
                  <h5>Site progress update, July 2023 – Great George Street</h5>
                  <p>
                    Progress update at our Leeds City Centre project, 2 Great
                    George Street. Works are progressing well at our landmark
                    project [&hellip;]
                  </p>
                  <span className="c-Title c-Cards_Btn u-Underline u-Underline-push">
                    View project
                  </span>
                </div>
              </a>
            </li>
          </ol>
          <nav className="c-Pagination">
            <span
              aria-current="page"
              className="c-Btn c-Pagination_Link c-Pagination_Link-current"
            >
              1
            </span>
            <a
              className="c-Btn c-Pagination_Link"
              href="https://priestleyconstruction.co.uk/news-and-updates.htmlpage/2/"
            >
              2
            </a>
            <span className="c-Btn c-Pagination_Link c-Pagination_Link-dots">
              &hellip;
            </span>
            <a
              className="c-Btn c-Pagination_Link"
              href="https://priestleyconstruction.co.uk/news-and-updates.htmlpage/6/"
            >
              6
            </a>
            <a
              className="c-Btn c-Pagination_Link c-Pagination_Link-next"
              href="https://priestleyconstruction.co.uk/news-and-updates.htmlpage/2/"
            >
              &rarr;
            </a>
            <span className="c-Pagination_Count">6 pages with 72 posts</span>
          </nav>
        </div>
      </div>
      <div className="o-Section o-Section-bg-grey-lighter o-Section-no-switch">
        <div className="o-Center c-Newsletter">
          <h2 className="o-M30 c-Title u-TextAlignCenter">Hear more from us</h2>
          <p className="o-M30 u-TextAlignCenter">
            Get the inside scoop on our VIP launches, early bird offers, latest
            projects, company news and more.
          </p>
        </div>
      </div>
    </main>
  );
};

export default News;
