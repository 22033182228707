import React from "react";

const Design = () => {
  return (
    <main className="c-Page_Main">
      <header className="c-Banner">
        <div
          className="c-Banner_Inner is-loaded is-background"
          data-src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2018/10/19153530/priestley-construction-banner-02.jpg"
          style={{
            backgroundImage:
              "url(https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2018/10/19153530/priestley-construction-banner-02.jpg)",
          }}
        >
          <div className="o-Container c-Banner_Content">
            <h1 className="c-Banner_Title">Design</h1>
            <h3 className="o-M30 o-P20 c-Banner_Subheading">
              Renowned for revolutionising spaces around the UK. Our design
              experts are here to streamline the construction process
            </h3>
          </div>
        </div>
      </header>
      <div className="o-Section o-Section-bg-grey-lighter">
        <div className="o-Container">
          <div className="o-Flex o-Flex-center o-Section_Cols">
            <div className="o-Section_Col c-Content c-Content-limit">
              <h2>Maximising potential and value engineering</h2>
              <p>
                Priestley Construction is adept at finding ways to make your
                space fresh and exciting. We strategise elements of your floor
                plan and structure to maximise the space’s potential– always
                keeping in mind latest and international trends, which we mould
                to your requirements. Let us be your design team from the very
                beginning, and we’ll provide expert value engineering from years
                of construction experience, to deliver value as well as an
                exemplary finish.
              </p>
              <h2>In-house expertise</h2>
              <p>
                Forget juggling your various development teams. Priestley
                Construction can support your design plan from feasibility right
                through to delivery. We have our own architects, both at
                chartered and junior level, to ensure design choices are not
                only beautiful, but functional. This is something that few
                contractors offer in the UK. One contact, no excuses; we hold
                full accountability to save you time and money.
              </p>
              <h2>A perfect fit</h2>
              <p>
                Hand over as much power as you see fit. Priestley Construction
                will learn what you want, advise on subsequent decisions, and
                carry out as much of the work as you like. We take full
                responsibility for the tasks we’re assigned; our commitments and
                yours are a mutual mark of success.
              </p>
            </div>
            <div className="o-Section_Col">
              <figure className="c-Figure c-Figure-right">
                <img
                  src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2018/05/21001549/priestley-construction-office.jpg"
                  className="c-Image c-Image-full-width"
                  loading="lazy"
                  width="800"
                  height="1000"
                  title="priestley-construction-office"
                  alt=""
                />
              </figure>
            </div>
          </div>
        </div>
      </div>
      <div className="o-Section o-Section-bg-grey-lighter c-Testimonial">
        <div className="o-Container">
          <div className="o-Flex o-Flex-center o-Section_Cols c-Testimonial_Inner">
            <div className="o-Section_Col c-Testimonial_Image">
              <img
                src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2018/10/19153923/priestley-construction-content-021.jpg"
                className="c-Image c-Image-full-width"
                loading="lazy"
                width="700"
                height="477"
                title="priestley-construction-content-021"
                alt=""
              />
              <div className="o-Flex o-Flex-column o-Flex-align-center c-Testimonial_Logo">
                <img
                  src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2018/04/19154315/gjr-developments-logo-1.svg"
                  className="c-Image c-Image-full-width"
                  loading="lazy"
                  width="1"
                  height="1"
                  title="gjr-developments-logo-1"
                  alt=""
                />
              </div>
            </div>
            <div className="o-Section_Col c-Testimonial_Content">
              <p className="o-M10 c-Testimonial_Quote">
                A reliable, innovative and expert construction company
              </p>
              <span>- Gill Archer, GJR Developments</span>
            </div>
          </div>
        </div>
      </div>

      <div className="o-Section o-Section-bg-grey-lighter o-Section-no-switch">
        <div className="o-Center c-Newsletter">
          <h2 className="o-M30 c-Title u-TextAlignCenter">Hear more from us</h2>
          <p className="o-M30 u-TextAlignCenter">
            Get the inside scoop on our VIP launches, early bird offers, latest
            projects, company news and more.
          </p>
        </div>
      </div>
    </main>
  );
};

export default Design;
