import React from "react";

const Commercial = () => {
  return (
    <main className="c-Page_Main">
      <header className="o-Section o-Section-bg-grey-lighter c-PageBanner">
        <div className="o-Container">
          <h1 className="c-Title c-PageBanner_Title">Commercial</h1>
        </div>
      </header>
      <div className="o-Section o-Section-bg-grey-lighter">
        <div className="o-Container">
          <ul className="o-M100 o-Flex o-Flex-wrap o-Flex-gutters c-ImageCards">
            <li className="o-Flex_Block c-ImageCards_Item">
              <a href="https://priestleyconstruction.co.uk/project/premium-offices/">
                <img
                  src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2020/03/01104932/The-Bridge-Inn-1-700x458.jpg"
                  className="c-Image o-M15 c-Image-full-width"
                  loading="lazy"
                  width="700"
                  height="458"
                  title="The Bridge Inn 1"
                  alt=""
                />
                <h3 className="c-Title">Premium Offices</h3>
                <span className="o-M10 c-Title c-ImageCards_Subtitle u-ColorBrand">
                  Wakefield
                </span>
              </a>
            </li>
            <li className="o-Flex_Block c-ImageCards_Item">
              <a href="https://priestleyconstruction.co.uk/project/60-wellington-street/">
                <img
                  src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2019/04/16143527/priestley-construction-60-wellington-st-02-700x458.jpg"
                  className="c-Image o-M15 c-Image-full-width"
                  loading="lazy"
                  width="700"
                  height="458"
                  title="priestley-construction-wellington-street"
                  alt="Exterior view of 60 wellington street project"
                />
                <h3 className="c-Title">60 Wellington Street</h3>
                <span className="o-M10 c-Title c-ImageCards_Subtitle u-ColorBrand">
                  Leeds
                </span>
              </a>
            </li>
            <li className="o-Flex_Block c-ImageCards_Item">
              <a href="https://priestleyconstruction.co.uk/project/great-horton-road/">
                <img
                  src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2018/05/19144456/DSC_0407-700x458.jpg"
                  className="c-Image o-M15 c-Image-full-width"
                  loading="lazy"
                  width="700"
                  height="458"
                  title="DSC_0407"
                  alt=""
                />
                <h3 className="c-Title">Great Horton Road</h3>
                <span className="o-M10 c-Title c-ImageCards_Subtitle u-ColorBrand">
                  Bradford
                </span>
              </a>
            </li>
            <li className="o-Flex_Block c-ImageCards_Item">
              <a href="https://priestleyconstruction.co.uk/project/millwood-house/">
                <img
                  src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2018/04/29082943/priestley-construction-millwood-house-08-700x458.jpg"
                  className="c-Image o-M15 c-Image-full-width"
                  loading="lazy"
                  width="700"
                  height="458"
                  title="priestley-construction-millwood-house-08"
                  alt=""
                />
                <h3 className="c-Title">Millwood House</h3>
                <span className="o-M10 c-Title c-ImageCards_Subtitle u-ColorBrand">
                  Leeds
                </span>
              </a>
            </li>
            <li className="o-Flex_Block c-ImageCards_Item">
              <a href="https://priestleyconstruction.co.uk/project/priestley-house/">
                <img
                  src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2018/04/19151919/HQ-Night-Time-051-700x458.jpg"
                  className="c-Image o-M15 c-Image-full-width"
                  loading="lazy"
                  width="700"
                  height="458"
                  title="HQ-Night-Time-051"
                  alt=""
                />
                <h3 className="c-Title">Priestley House</h3>
                <span className="o-M10 c-Title c-ImageCards_Subtitle u-ColorBrand">
                  Leeds
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="o-Section o-Section-bg-grey-lighter o-Section-no-switch">
        <div className="o-Center c-Newsletter">
          <h2 className="o-M30 c-Title u-TextAlignCenter">Hear more from us</h2>
          <p className="o-M30 u-TextAlignCenter">
            Get the inside scoop on our VIP launches, early bird offers, latest
            projects, company news and more.
          </p>
        </div>
      </div>
    </main>
  );
};

export default Commercial;
