import React from "react";

const Projects = () => {
  return (
    <main className="c-Page_Main">
      <header className="o-Section o-Section-bg-grey-lighter c-PageBanner">
        <div className="o-Container">
          <h1 className="c-Title c-PageBanner_Title">Current Projects</h1>
          <h2 className="o-P20 c-Title c-PageBanner_Subtitle">
            From listed building conversions to exceptional office
            refurbishments, there is plenty of exciting work in the Priestley
            Construction pipeline. We’re experts at rejuvenating old structures
            and pushing new builds to the forefront of the market, always aiming
            for the biggest returns at the smallest cost.
          </h2>
        </div>
      </header>
      <div className="o-Section o-Section-bg-grey-lighter">
        <div className="o-Container">
          <ul className="o-M100 o-Flex o-Flex-wrap o-Flex-gutters c-ImageCards">
            <li className="o-Flex_Block c-ImageCards_Item">
              <a href="https://priestleyconstruction.co.uk/project/the-paddocks/">
                <img
                  src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2022/10/Knuts-VP5-RevB-gigapixel-standard-scale-2_00x-700x458.jpg"
                  className="c-Image o-M15 c-Image-full-width"
                  loading="lazy"
                  width="700"
                  height="458"
                  title="Knuts VP5 RevB-gigapixel-standard-scale-2_00x"
                  alt=""
                />
                <h3 className="c-Title">The Paddocks</h3>
                <span className="o-M10 c-Title c-ImageCards_Subtitle u-ColorBrand">
                  Knutsford
                </span>
              </a>
            </li>
            <li className="o-Flex_Block c-ImageCards_Item">
              <a href="https://priestleyconstruction.co.uk/project/luxury-apartments-coming-to-great-george-street-leeds/">
                <img
                  src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2021/05/04163422/DSC_0146_rev-700x458.jpg"
                  className="c-Image o-M15 c-Image-full-width"
                  loading="lazy"
                  width="700"
                  height="458"
                  title="Great George Street building in Leeds"
                  alt="Great George Street building in Leeds"
                />
                <h3 className="c-Title">Great George Street</h3>
                <span className="o-M10 c-Title c-ImageCards_Subtitle u-ColorBrand">
                  Leeds
                </span>
              </a>
            </li>
            <li className="o-Flex_Block c-ImageCards_Item">
              <a href="https://priestleyconstruction.co.uk/project/marygate-house/">
                <img
                  src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2021/05/05095141/DSC_0736-700x458.jpg"
                  className="c-Image o-M15 c-Image-full-width"
                  loading="lazy"
                  width="700"
                  height="458"
                  title="Marygate House in the heart of Wakefield city centre"
                  alt="Marygate House in the heart of Wakefield city centre"
                />
                <h3 className="c-Title">Marygate House</h3>
                <span className="o-M10 c-Title c-ImageCards_Subtitle u-ColorBrand">
                  Wakefield
                </span>
              </a>
            </li>
          </ul>
          <div className="u-TextAlignCenter">
            <h2 className="o-M4 c-Title">Completed projects</h2>
            <a
              href="https://priestleyconstruction.co.uk/projects/status/completed/"
              className="o-Flex_Push c-Btn c-Btn-clean"
            >
              View Projects &nbsp;&nbsp;
              <span className="c-Icon c-Icon-long-arrow-right-dark"></span>
            </a>
          </div>
        </div>
      </div>
      <div className="o-Section o-Section-bg-grey-lighter o-Section-no-switch">
        <div className="o-Center c-Newsletter">
          <h2 className="o-M30 c-Title u-TextAlignCenter">Hear more from us</h2>
          <p className="o-M30 u-TextAlignCenter">
            Get the inside scoop on our VIP launches, early bird offers, latest
            projects, company news and more.
          </p>
        </div>
      </div>
    </main>
  );
};

export default Projects;
