import React from "react";

const Career = () => {
  return (
    <main className="c-Page_Main">
      <header className="o-Section o-Section-bg-grey-lighter c-PageBanner">
        <div className="o-Container">
          <h1 className="c-Title c-PageBanner_Title">Careers</h1>
          <h2 className="o-P20 c-Title c-PageBanner_Subtitle">
            <p>
              Whether you&#8217;re an architect, consultant, contracts manager
              or joiner, we&#8217;d love to hear from you.
              <a href="https://priestleyconstruction.co.uk/our-team/">
                Head here to meet the team before applying.
              </a>
            </p>
          </h2>
        </div>
      </header>
      <div className="o-Section o-Section-bg-grey-lighter">
        <div className="o-Container">
          <ul>
            <li className="c-Job">
              <a
                href="https://priestleyconstruction.co.uk/career/site-manager-work-placement/"
                className="o-Flex o-Flex-center c-Job_Inner"
              >
                <h3 className="c-Title c-Job_Title">
                  Site Manager &#8211; Work Placement
                </h3>
                <span className="c-Job_Type">Permanent</span>
                <span className="c-Job_Location">TBC</span>
              </a>
            </li>
            <li className="c-Job">
              <a
                href="https://priestleyconstruction.co.uk/career/workplacement/"
                className="o-Flex o-Flex-center c-Job_Inner"
              >
                <h3 className="c-Title c-Job_Title">
                  Quantity Surveyor – Work Placement
                </h3>
                <span className="c-Job_Type">Permanent</span>
                <span className="c-Job_Location">TBC</span>
              </a>
            </li>
            <li className="c-Job">
              <a
                href="https://priestleyconstruction.co.uk/career/quantity-surveyor/"
                className="o-Flex o-Flex-center c-Job_Inner"
              >
                <h3 className="c-Title c-Job_Title">Quantity Surveyor</h3>
                <span className="c-Job_Type">Permanent</span>
                <span className="c-Job_Location">Leeds (North)</span>
              </a>
            </li>
            <li className="c-Job">
              <a
                href="https://priestleyconstruction.co.uk/career/contracts-manager/"
                className="o-Flex o-Flex-center c-Job_Inner"
              >
                <h3 className="c-Title c-Job_Title">Contracts Manager</h3>
                <span className="c-Job_Type">Permanent</span>
                <span className="c-Job_Location">Leeds (North)</span>
              </a>
            </li>
            <li className="c-Job">
              <a
                href="https://priestleyconstruction.co.uk/career/estimator/"
                className="o-Flex o-Flex-center c-Job_Inner"
              >
                <h3 className="c-Title c-Job_Title">Estimator</h3>
                <span className="c-Job_Type">Permanent</span>
                <span className="c-Job_Location">Leeds (North)</span>
              </a>
            </li>
            <li className="c-Job">
              <a
                href="https://priestleyconstruction.co.uk/career/site-supervisor/"
                className="o-Flex o-Flex-center c-Job_Inner"
              >
                <h3 className="c-Title c-Job_Title">Site Supervisor</h3>
                <span className="c-Job_Type">Permanent</span>
                <span className="c-Job_Location">Leeds (North)</span>
              </a>
            </li>
            <li className="c-Job">
              <a
                href="https://priestleyconstruction.co.uk/career/project-manager/"
                className="o-Flex o-Flex-center c-Job_Inner"
              >
                <h3 className="c-Title c-Job_Title">
                  Project Manager (Site &#038; Office Based)
                </h3>
                <span className="c-Job_Type">Permanent</span>
                <span className="c-Job_Location">Leeds (North)</span>
              </a>
            </li>
            <li className="c-Job">
              <a
                href="https://priestleyconstruction.co.uk/career/site-manager/"
                className="o-Flex o-Flex-center c-Job_Inner"
              >
                <h3 className="c-Title c-Job_Title">Site Manager</h3>
                <span className="c-Job_Type">Permanent</span>
                <span className="c-Job_Location">Leeds (North)</span>
              </a>
            </li>
            <li className="c-Job">
              <a
                href="https://priestleyconstruction.co.uk/career/business-development-manager-2/"
                className="o-Flex o-Flex-center c-Job_Inner"
              >
                <h3 className="c-Title c-Job_Title">
                  Business Development Manager
                </h3>
                <span className="c-Job_Type">Permanent</span>
                <span className="c-Job_Location">London (South)</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="o-Section o-Section-bg-grey-lighter o-Section-no-switch">
        <div className="o-Center c-Newsletter">
          <h2 className="o-M30 c-Title u-TextAlignCenter">Hear more from us</h2>
          <p className="o-M30 u-TextAlignCenter">
            Get the inside scoop on our VIP launches, early bird offers, latest
            projects, company news and more.
          </p>
        </div>
      </div>
    </main>
  );
};

export default Career;
