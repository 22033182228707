import React from "react";

const StudentAccommodation = () => {
  return (
    <main className="c-Page_Main">
      <header className="o-Section o-Section-bg-grey-lighter c-PageBanner">
        <div className="o-Container">
          <h1 className="c-Title c-PageBanner_Title">Student Accommodation</h1>
        </div>
      </header>
      <div className="o-Section o-Section-bg-grey-lighter">
        <div className="o-Container">
          <ul className="o-M100 o-Flex o-Flex-wrap o-Flex-gutters c-ImageCards">
            <li className="o-Flex_Block c-ImageCards_Item">
              <a href="https://priestleyconstruction.co.uk/project/cablestreet/">
                <img
                  src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2020/04/Cable-St-front-external-700x458.jpg"
                  className="c-Image o-M15 c-Image-full-width"
                  loading="lazy"
                  width="700"
                  height="458"
                  title="Cable St front external"
                  alt=""
                />
                <h3 className="c-Title">Cable Street</h3>
                <span className="o-M10 c-Title c-ImageCards_Subtitle u-ColorBrand">
                  Lancaster
                </span>
              </a>
            </li>
            <li className="o-Flex_Block c-ImageCards_Item">
              <a href="https://priestleyconstruction.co.uk/project/grattan-house/">
                <img
                  src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2018/05/19145811/861-700x458.jpg"
                  className="c-Image o-M15 c-Image-full-width"
                  loading="lazy"
                  width="700"
                  height="458"
                  title="861"
                  alt=""
                />
                <h3 className="c-Title">Grattan House</h3>
                <span className="o-M10 c-Title c-ImageCards_Subtitle u-ColorBrand">
                  Bradford
                </span>
              </a>
            </li>
            <li className="o-Flex_Block c-ImageCards_Item">
              <a href="https://priestleyconstruction.co.uk/project/the-grand-mill/">
                <img
                  src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2018/05/21002040/priestley-construction-the-grand-mill-10-700x458.jpg"
                  className="c-Image o-M15 c-Image-full-width"
                  loading="lazy"
                  width="700"
                  height="458"
                  title="priestley-construction-the-grand-mill-10"
                  alt=""
                />
                <h3 className="c-Title">The Grand Mill</h3>
                <span className="o-M10 c-Title c-ImageCards_Subtitle u-ColorBrand">
                  Bradford
                </span>
              </a>
            </li>
            <li className="o-Flex_Block c-ImageCards_Item">
              <a href="https://priestleyconstruction.co.uk/project/rivermill-court/">
                <img
                  src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2018/05/13150146/priestley-homes-rivermill-court-external-700x458.jpg"
                  className="c-Image o-M15 c-Image-full-width"
                  loading="lazy"
                  width="700"
                  height="458"
                  title="priestley-homes-rivermill-court-external"
                  alt=""
                />
                <h3 className="c-Title">Rivermill Court</h3>
                <span className="o-M10 c-Title c-ImageCards_Subtitle u-ColorBrand">
                  Leeds
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="o-Section o-Section-bg-grey-lighter o-Section-no-switch">
        <div className="o-Center c-Newsletter">
          <h2 className="o-M30 c-Title u-TextAlignCenter">Hear more from us</h2>
          <p className="o-M30 u-TextAlignCenter">
            Get the inside scoop on our VIP launches, early bird offers, latest
            projects, company news and more.
          </p>
        </div>
      </div>
    </main>
  );
};

export default StudentAccommodation;
