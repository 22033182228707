import React from "react";

const Construction = () => {
  return (
    <main className="c-Page_Main">
      <header className="c-Banner">
        <div
          className="c-Banner_Inner is-loaded is-background"
          data-src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2018/10/19153346/priestley-construction-banner-04.jpg"
          style={{
            backgroundImage:
              "url(https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2018/10/19153346/priestley-construction-banner-04.jpg)",
          }}
        >
          <div className="o-Container c-Banner_Content">
            <h1 className="c-Banner_Title">Construction</h1>
            <h3 className="o-M30 o-P20 c-Banner_Subheading">
              We refresh old spaces, and build new ones from the ground up.
            </h3>
          </div>
        </div>
      </header>
      <div className="o-Section o-Section-bg-grey-lighter">
        <div className="o-Container">
          <div className="o-Flex o-Flex-center o-Section_Cols">
            <div className="o-Section_Col c-Content c-Content-limit">
              <h2>Conversions</h2>
              <p>
                Converting buildings takes skill, foresight and sensitivity.
                This applies both to the building itself and the investment
                objectives that are relying on a successful result. Whether you
                want to make a traditional structure fit for commercial use, or
                make industrial buildings suitable for living, Priestley
                Construction will see the conversion through from start to
                finish.
              </p>
              <h2>Refurbishments</h2>
              <p>
                Half of our worldwide reputation stems from our ability to
                refurbish buildings for the modern market. Readying a building
                for sale? Got a fresh design in mind? Priestley Construction
                will advise, plan and enact your vision, while retaining the
                character of the original build. Our speed and talent is
                unmatched for UK development.
              </p>
              <h2>New builds</h2>
              <p>
                Although we stay true to our speciality, which is conversions
                and refurbishments, we’ve successfully delivered a number of
                new-build projects across the UK. Our time in conversion and
                refurbishment has taught us which materials, specs, tools and
                physical considerations work well together. Using that
                knowledge, Priestley Construction is able to offer an end-to-end
                solution for new build, large scale extensions or additional
                storeys.
              </p>
            </div>
            <div className="o-Section_Col">
              <figure className="c-Figure c-Figure-right">
                <img
                  src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2018/05/21012302/priestley-construction-under-construction-02.jpg"
                  className="c-Image c-Image-full-width"
                  loading="lazy"
                  width="800"
                  height="1067"
                  title="priestley-construction-under-construction-02"
                  alt=""
                />
              </figure>
            </div>
          </div>
        </div>
      </div>

      <div className="o-Section o-Section-bg-grey-lighter o-Section-no-switch">
        <div className="o-Center c-Newsletter">
          <h2 className="o-M30 c-Title u-TextAlignCenter">Hear more from us</h2>
          <p className="o-M30 u-TextAlignCenter">
            Get the inside scoop on our VIP launches, early bird offers, latest
            projects, company news and more.
          </p>
        </div>
      </div>
    </main>
  );
};

export default Construction;
