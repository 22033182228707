import React from "react";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <div className="c-Page_Main">
      <header className="c-Banner c-Banner-front-page">
        <div
          className="c-Banner_Inner is-loaded is-background"
          data-src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2018/05/24234453/priestley-construction-home-banner.jpg"
          style={{
            backgroundImage:
              "url(https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2018/05/24234453/priestley-construction-home-banner.jpg)",
          }}
          // style="
          //   background-image: url('https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2018/05/24234453/priestley-construction-home-banner.jpg');
          // "
        >
          <div className="o-Container c-Banner_Content">
            <h1 className="o-M20 c-Banner_Title">
              <span className="u-VisuallyHidden">
                Priestley Construction -{" "}
              </span>
              The building blocks for excellence
            </h1>
            <h3 className="o-M60 c-Banner_Subtitle">
              We{" "}
              <span className="c-Banner_Underline js-Type">build offices</span>
              <span
                className="typed-cursor"
                //   style="animation-iteration-count: infinite"
              >
                |
              </span>
            </h3>
            <Link to="/contact-us" className="c-Btn c-Btn-fill">
              About Us
            </Link>
          </div>
        </div>
      </header>
      <div className="c-Updates">
        <div className="c-Updates_Inner">
          <div className="o-Container o-Flex c-Updates_Container">
            <div className="o-Flex o-Flex-center c-Updates_Feed">
              <span className="c-Icon c-Icon-badge c-Updates_Badge"></span>
              <p className="c-Updates_PostTitle">
                Recent update: Award Win At The Prestigious International
                Property Awards
              </p>
              2 weeks ago
            </div>

            <span>
              Project updates &amp; <br />
              latest news
            </span>
            <span className="c-Icon c-Icon-arrow-right c-Updates_BtnIcon"></span>
          </div>
        </div>
      </div>
      <div className="o-Section o-Section-bg-grey-lighter">
        <div className="o-Container o-Flex o-Flex-center o-Section_Cols">
          <div className="c-Feature">
            <div className="c-Content c-Feature_Content">
              <h2>
                Your <em>focus</em> is our focus
              </h2>
              <p>
                We cater for the developers of tomorrow. Whether you’re
                embarking on a residential or commercial project, we focus on
                saving time and money without compromising on excellence. Our
                construction team break with tradition to achieve the best value
                and finish in the sector.
              </p>
            </div>
          </div>
          <div className="o-Flex_Grow c-Content c-Content-limit">
            <h2>Craft, speed and integrity</h2>
            <p>
              The desire for buildings designed to accommodate the busy
              lifestyles of their occupants has never been greater. At
              Priestley, we know this, and work closely with our partners to
              develop residential and commercial properties that are both
              aspirational and sustainable.
            </p>
            <p>
              We design, build and refurbish with unmatched attention to detail.
              It’s this commitment to delivering high standards at speed and
              scale that has earned us our global reputation.
            </p>
          </div>
        </div>
      </div>
      <div className="o-Section o-Section-bg-grey-lighter">
        <div className="o-Container">
          <div className="o-M30 u-PushFront">
            <h2 className="o-M4 c-Title">Completed projects</h2>

              All Completed Projects&nbsp;&nbsp;
              <span className="c-Icon c-Icon-long-arrow-right-dark"></span>
           
          </div>
          <ul className="o-M100 o-Flex o-Flex-wrap o-Flex-gutters c-ImageCards">
            <li className="o-Flex_Block c-ImageCards_Item">

                <img
                  src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2022/10/Conditioning-House-700x458.jpg"
                  className="c-Image o-M15 c-Image-full-width"
                  loading="lazy"
                  width="700"
                  height="458"
                  title="Conditioning House"
                  alt=""
                />
                <h3 className="c-Title">Conditioning House Phase 4</h3>
                <span className="o-M10 c-Title c-ImageCards_Subtitle u-ColorBrand">
                  Bradford
                </span>
             
            </li>
            <li className="o-Flex_Block c-ImageCards_Item">
              
                <img
                  src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2022/10/AJS4778-700x458.jpg"
                  className="c-Image o-M15 c-Image-full-width"
                  loading="lazy"
                  width="700"
                  height="458"
                  title="_AJS4778"
                  alt=""
                />
                <h3 className="c-Title">12 Piccadilly</h3>
                <span className="o-M10 c-Title c-ImageCards_Subtitle u-ColorBrand">
                  Bradford
                </span>
             
            </li>
            <li className="o-Flex_Block c-ImageCards_Item">
              
                <img
                  src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2020/04/Cable-St-front-external-700x458.jpg"
                  className="c-Image o-M15 c-Image-full-width"
                  loading="lazy"
                  width="700"
                  height="458"
                  title="Cable St front external"
                  alt=""
                />
                <h3 className="c-Title">Cable Street</h3>
                <span className="o-M10 c-Title c-ImageCards_Subtitle u-ColorBrand">
                  Lancaster
                </span>
             
            </li>
            <li className="o-Flex_Block c-ImageCards_Item">
              
                <img
                  src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2020/02/1-700x458.jpg"
                  className="c-Image o-M15 c-Image-full-width"
                  loading="lazy"
                  width="700"
                  height="458"
                  title="1"
                  alt=""
                />
                <h3 className="c-Title">Cliff Oaks</h3>
                <span className="o-M10 c-Title c-ImageCards_Subtitle u-ColorBrand">
                  Leeds
                </span>
             
            </li>
          </ul>
          {/* <div className="u-TextAlignCenter">
            <h2 className="o-M4 c-Title">Current projects</h2>
            
              View Projects &nbsp;&nbsp;
              <span className="c-Icon c-Icon-long-arrow-right-dark"></span>
           
          </div> */}
        </div>
      </div>
      <div className="o-Section o-Section-bg-grey-darker o-Section-push">
        <div className="o-Container">
          <h3 className="o-M50 c-TitleAlt">Service areas</h3>
          <div className="o-Flex o-Flex-wrap o-Flex-gutters c-Highlights">
            <div className="o-Flex_Block c-Highlights_Item">
              <span className="o-M10 c-Icon c-Icon-design c-Highlights_Icon"></span>
              <h4 className="o-M10">
                Design
              </h4>
              <p className="o-M10 c-Highlights_Text">
                We are one of the only construction companies in the UK with an
                in house architectural team. Tell us your concept, and we’ll
                make it a reality. From planning and value engineering, to
                resourcing and delivery, Priestley Construction can create the
                perfect blueprint.
              </p>
            </div>
            <div className="o-Flex_Block c-Highlights_Item">
              <span className="o-M10 c-Icon c-Icon-conversion c-Highlights_Icon"></span>
              <h4 className="o-M10">
                
                  Conversion
                
              </h4>
              <p className="o-M10 c-Highlights_Text">
                Our reputation was built on transforming aged buildings to
                compete with contemporary design expectations. A top-down
                conversion can make an old space feel indispensable for today’s
                market.
              </p>
            </div>
            <div className="o-Flex_Block c-Highlights_Item">
              <span className="o-M10 c-Icon c-Icon-refurbishment c-Highlights_Icon"></span>
              <h4 className="o-M10">
                
                  Refurbishment
               
              </h4>
              <p className="o-M10 c-Highlights_Text">
                Do you have a building that needs refurbishing? Whether it’s an
                office, library, retail or residential space, we are experts in
                this field and can transform a tired space into a vibrant
                masterpiece.
              </p>
            </div>
            <div className="o-Flex_Block c-Highlights_Item">
              <span className="o-M10 c-Icon c-Icon-personal-consultation c-Highlights_Icon"></span>
              <h4 className="o-M10">Personal consultation</h4>
              <p className="o-M10 c-Highlights_Text">
                From UK developments to international projects, Priestley
                Construction is ready to meet you and explore your portfolio
                aims. We provide face-to-face consultations to discuss your
                exact requirements and formulate a plan for creating your
                particular vision.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="c-ImageBlock">
        <div className="o-Flex c-ImageBlock_QuoteWrap">
          <div className="c-ImageBlock_Quote">
            <blockquote className="o-Flex c-ImageBlock_QuoteInner">
              <p className="o-Flex_Grow">
                <span className="u-ColorBrand">&ldquo;</span> Priestley
                Construction is a brand recognised
                <strong>worldwide</strong> for delivering exceptional quality
                projects <span className="u-ColorBrand">&rdquo;</span>
              </p>
              <span className="c-Icon c-Icon-badge c-ImageBlock_QuoteBadge"></span>
            </blockquote>
          </div>
        </div>
        <div className="o-Container c-ImageBlock_Content">
          <h3 className="o-M15 c-TitleAlt">Priestley Construction</h3>
          <p className="c-ImageBlock_Caption">
            Award <i>winning</i> construction designed for life
          </p>
        </div>
        <div className="c-ImageBlock_ImageWrap">
          <img
            src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2018/10/19153718/priestley-construction-image-block.jpg"
            className="c-Image c-Image-full-width c-ImageBlock_Image"
            loading="lazy"
            width="1920"
            height="941"
            title="priestley-construction-image-block"
            alt="Priestley construction design build refurbish"
          />
        </div>
      </div>
      <div className="o-Section o-Section-bg-grey-lighter">
        <div className="o-Container">
          <div className="o-M30">
            <h2 className="o-M4 c-Title">
              Latest News <br />
              &amp; Project Updates
            </h2>
            <Link
              to="/"
              className="o-Flex_Push c-Btn c-Btn-clean"
            >
              All news &amp; updates&nbsp;&nbsp;
              <span className="c-Icon c-Icon-long-arrow-right-dark"></span>
            </Link>
          </div>
          <ol className="o-Flex o-Flex-wrap c-Cards">
            <li className="c-Cards_Item">
              <Link
                to="/"
                className="c-Cards_ItemInner"
              >
                <div className="c-Cards_Image">
                  <img
                    src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2023/11/priestley-group-uk-property-awards-2023-03-700x458.jpg"
                    className="c-Image c-Image-full-width"
                    loading="lazy"
                    width="700"
                    height="458"
                    title="priestley-group-uk-property-awards-2023-03"
                    alt=""
                  />
                </div>
                <div className="c-Content c-Cards_Content">
                  <div className="o-Flex o-Flex-center c-Cards_Meta">
                    <span className="c-Title c-Cards_Type">News</span>
                    <span className="c-Title c-Cards_Time">2 weeks ago</span>
                  </div>
                  <h5>
                    Award Win At The Prestigious International Property Awards
                  </h5>
                  <p>
                    We have won an award at the prestigious International
                    Property Awards. We won the award for best new small hotel
                    [&hellip;]
                  </p>
                  <span className="c-Title c-Cards_Btn u-Underline u-Underline-push">
                    Read more
                  </span>
                </div>
              </Link>
            </li>
            <li className="c-Cards_Item">
              <Link
                to="/"
                className="c-Cards_ItemInner"
              >
                <div className="c-Cards_Image">
                  <img
                    src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2023/11/9-700x458.jpg"
                    className="c-Image c-Image-full-width"
                    loading="lazy"
                    width="700"
                    height="458"
                    title="9"
                    alt=""
                  />
                </div>
                <div className="c-Content c-Cards_Content">
                  <div className="o-Flex o-Flex-center c-Cards_Meta">
                    <span className="c-Title c-Cards_Type c-Cards_Type-alt">
                      Project Update
                    </span>
                    <span className="c-Title c-Cards_Time">4 weeks ago</span>
                  </div>
                  <h5>Site progress update, October 2023 – Marygate House</h5>
                  <p>
                    Progress update at our Wakefield city centre project for
                    repeat clients @deuce_hotels We will be converting this
                    building into a [&hellip;]
                  </p>
                  <span className="c-Title c-Cards_Btn u-Underline u-Underline-push">
                    View project
                  </span>
                </div>
              </Link>
            </li>
            <li className="c-Cards_Item">
              <Link
                to="/"
                className="c-Cards_ItemInner"
              >
                <div className="c-Cards_Image">
                  <img
                    src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2023/11/Site-progress-update-October-2023-Great-George-Street-558x458.jpg"
                    className="c-Image c-Image-full-width"
                    loading="lazy"
                    width="558"
                    height="458"
                    title="Site-progress-update-October-2023-Great-George-Street"
                    alt=""
                  />
                </div>
                <div className="c-Content c-Cards_Content">
                  <div className="o-Flex o-Flex-center c-Cards_Meta">
                    <span className="c-Title c-Cards_Type c-Cards_Type-alt">
                      Project Update
                    </span>
                    <span className="c-Title c-Cards_Time">4 weeks ago</span>
                  </div>
                  <h5>
                    Site progress update, October 2023 – Great George Street
                  </h5>
                  <p>
                    Progress update at Leeds Most Exclusive Address, we have
                    seen the concrete poured on the new fifth deck and after
                    [&hellip;]
                  </p>
                  <span className="c-Title c-Cards_Btn u-Underline u-Underline-push">
                    View project
                  </span>
                </div>
              </Link>
            </li>
          </ol>
        </div>
      </div>

      <div className="o-Section o-Section-bg-grey-lighter o-Section-no-switch">
        <div className="o-Center c-Newsletter">
          <h2 className="o-M30 c-Title u-TextAlignCenter">Hear more from us</h2>
          <p className="o-M30 u-TextAlignCenter">
            Get the inside scoop on our VIP launches, early bird offers, latest
            projects, company news and more.
          </p>
          {/* <script
            //   charset="utf-8"
              type="8ad923ba6fb137c93b56adef-text/javascript"
              src="//js.hsforms.net/forms/shell.js"
            ></script>
            <script type="8ad923ba6fb137c93b56adef-text/javascript">
              hbspt.forms.create({
                //   region: "na1",
                  portalId: "9427314",
                  formId: "7ed6d26c-abba-46f2-b27c-8caa0c834fe4"
              });
            </script> */}
        </div>
      </div>
    </div>
  );
};

export default Home;
