import React, { useEffect, useState } from "react";
import { SiteDataProps } from "../../models/LayoutModels";
import { IMAGE_URL } from "../../settings/config";
import { Link, useLocation } from "react-router-dom";

interface HeaderProps {
  siteData: SiteDataProps;
}

const Header = (props: HeaderProps) => {
  const [isOpenNav, setIsOpenNav] = useState(false)
  const location = useLocation();
  console.log(isOpenNav);
  useEffect(() => {
    setIsOpenNav(false);
  }, [location]);
  
  return (
    <header className={`c-Header ${isOpenNav ? "has-open-nav" : ""}`}>
      <div
        className="o-Container o-Flex o-Flex-wrap o-Flex-center"
        style={{ background: isOpenNav ? "#222" : "" }}
      >
        <Link to="/" className="c-Logo" title={props.siteData.app_name}>
          <img
            src={`${IMAGE_URL}/${props.siteData.logo}`}
            className="c-Image js-Logo"
            width={100}
            alt={props.siteData.app_name}
          />{" "}
        </Link>
        <button
          className={`c-NavBtn js-OpenNav ${isOpenNav ? "is-active" : ""}`}
          onClick={() => setIsOpenNav(!isOpenNav)}
        >
          <span className="c-NavBtn_Inner"></span>
          <span className="c-NavBtn_Inner"></span>
          <span className="c-NavBtn_Inner"></span>
          <span className="c-NavBtn_Inner"></span>
          <span className="u-VisuallyHidden">Menu</span>
        </button>
        <nav
          className={`c-Nav js-Nav`}
          //   itemscope=""
          //   itemtype="http://schema.org/SiteNavigationElement"
        >
          <ul className="o-Flex c-Nav_List">
            <li className="c-Nav_Item c-Nav_Item-0 c-Nav_Item-has-children js-HasSubnav">
              <div
                title="Who We Are"
                // itemprop="url"
                className="c-Nav_Link c-Nav_Link-0 js-OpenSubnav"
              >
                <span
                  //   itemprop="name"
                  className="c-Nav_Title"
                >
                  Who We Are
                </span>
                <span className="c-Icon c-Icon-arrow-right c-Nav_Icon"></span>
              </div>
              <div className="c-Nav_Submenu c-Nav_Submenu-0">
                <ul className="c-Nav_SubmenuList">
                  {/* <li className="c-Nav_Item c-Nav_Item-1">
                    <Link
                      title="Past, Present, Future"
                      to="/past-present-future"
                      className="c-Nav_Link c-Nav_Link-1"
                    >
                      <span
                        className="c-Nav_Title"
                      >
                        Past, Present, Future
                      </span>
                    </Link>
                  </li>
                  <li className="c-Nav_Item c-Nav_Item-1">
                    <Link
                      title="Our Values"
                      //   itemprop="url"
                      to="/our-values"
                      className="c-Nav_Link c-Nav_Link-1"
                    >
                      <span
                        //   itemprop="name"
                        className="c-Nav_Title"
                      >
                        Our Values
                      </span>
                    </Link>
                  </li> */}
                  <li className="c-Nav_Item c-Nav_Item-1">
                    <Link
                      title="Our Team"
                      //   itemprop="url"
                      to="/our-team"
                      className="c-Nav_Link c-Nav_Link-1"
                    >
                      <span
                        //   itemprop="name"
                        className="c-Nav_Title"
                      >
                        Our Team
                      </span>
                    </Link>
                  </li>
                  {/* <li className="c-Nav_Item c-Nav_Item-1">
                    <Link
                      title="Careers"
                      //   itemprop="url"
                      to="/careers"
                      className="c-Nav_Link c-Nav_Link-1"
                    >
                      <span
                        //   itemprop="name"
                        className="c-Nav_Title"
                      >
                        Careers
                      </span>
                    </Link>
                  </li> */}
                </ul>
              </div>
            </li>
            {/* <li className="c-Nav_Item c-Nav_Item-0 c-Nav_Item-has-children js-HasSubnav">
              <a
                title="What We Do"
                // itemprop="url"
                href="/"
                className="c-Nav_Link c-Nav_Link-0 js-OpenSubnav"
              >
                <span
                  //   itemprop="name"
                  className="c-Nav_Title"
                >
                  What We Do
                </span>
                <span className="c-Icon c-Icon-arrow-right c-Nav_Icon"></span>
              </a>
              <div className="c-Nav_Submenu c-Nav_Submenu-0">
                <ul className="c-Nav_SubmenuList">
                  <li className="c-Nav_Item c-Nav_Item-1">
                    <Link
                      title="Construction"
                      //   itemprop="url"
                      to="/construction"
                      className="c-Nav_Link c-Nav_Link-1"
                    >
                      <span
                        //   itemprop="name"
                        className="c-Nav_Title"
                      >
                        Construction
                      </span>
                    </Link>
                  </li>
                  <li className="c-Nav_Item c-Nav_Item-1">
                    <Link
                      title="Design"
                      //   itemprop="url"
                      to="/design"
                      className="c-Nav_Link c-Nav_Link-1"
                    >
                      <span
                        //   itemprop="name"
                        className="c-Nav_Title"
                      >
                        Design
                      </span>
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
            <li className="c-Nav_Item c-Nav_Item-0 c-Nav_Item-has-children js-HasSubnav">
              <a
                title="Projects"
                href="/projects"
                // itemprop="url"
                className="c-Nav_Link c-Nav_Link-0 js-OpenSubnav"
              >
                <span
                  //   itemprop="name"
                  className="c-Nav_Title"
                >
                  Projects
                </span>
                <span className="c-Icon c-Icon-arrow-right c-Nav_Icon"></span>
              </a>
              <div className="c-Nav_Submenu c-Nav_Submenu-0">
                <ul className="c-Nav_SubmenuList">
                  <li className="c-Nav_Item c-Nav_Item-1">
                    <Link
                      title="Current"
                      //   itemprop="url"
                      to="/projects"
                      className="c-Nav_Link c-Nav_Link-1"
                    >
                      <span
                        //   itemprop="name"
                        className="c-Nav_Title"
                      >
                        Current
                      </span>
                    </Link>
                  </li>
                  <li className="c-Nav_Item c-Nav_Item-1">
                    <Link
                      title="Completed"
                      //   itemprop="url"
                      to="/projects-completed"
                      className="c-Nav_Link c-Nav_Link-1"
                    >
                      <span
                        //   itemprop="name"
                        className="c-Nav_Title"
                      >
                        Completed
                      </span>
                    </Link>
                  </li>
                  <li className="c-Nav_Item c-Nav_Item-1">
                    <Link
                      title="Residential"
                      //   itemprop="url"
                      to="/projects-recidential"
                      className="c-Nav_Link c-Nav_Link-1"
                    >
                      <span
                        //   itemprop="name"
                        className="c-Nav_Title"
                      >
                        Residential
                      </span>
                    </Link>
                  </li>
                  <li className="c-Nav_Item c-Nav_Item-1">
                    <Link
                      title="Commercial"
                      //   itemprop="url"
                      to="/projects-commercial"
                      className="c-Nav_Link c-Nav_Link-1"
                    >
                      <span
                        //   itemprop="name"
                        className="c-Nav_Title"
                      >
                        Commercial
                      </span>
                    </Link>
                  </li>
                  <li className="c-Nav_Item c-Nav_Item-1">
                    <Link
                      title="Student Accommodation"
                      //   itemprop="url"
                      to="/projects-student-accommodation"
                      className="c-Nav_Link c-Nav_Link-1"
                    >
                      <span
                        //   itemprop="name"
                        className="c-Nav_Title"
                      >
                        Student Accommodation
                      </span>
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
            <li className="c-Nav_Item c-Nav_Item-0">
              <Link
                title="News &#038; Updates"
                // itemprop="url"
                to="/news"
                className="c-Nav_Link c-Nav_Link-0"
              >
                <span
                  //   itemprop="name"
                  className="c-Nav_Title"
                >
                  News &#038; Updates
                </span>
              </Link>
            </li> */}
            <li className="c-Nav_Item c-Nav_Item-0">
              <Link
                title="Contact Us"
                // itemprop="url"
                to="/contact-us"
                className="c-Nav_Link c-Nav_Link-0"
              >
                <span
                  //   itemprop="name"
                  className="c-Nav_Title"
                >
                  Contact Us
                </span>
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
