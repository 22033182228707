import axios from "axios";
import { API_URL } from "../settings/config";

export const fetchTeamsData = async () => {
  try {
    const response = await axios.get(`${API_URL}/get-teams`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
