import React from "react";

const OurValues = () => {
  return (
    <main className="c-Page_Main">
      <header className="c-Banner">
        <div
          className="c-Banner_Inner is-loaded is-background"
          data-src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2018/10/19153532/priestley-construction-banner-05.jpg"
          style={{
            backgroundImage:
              "url(https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2018/10/19153532/priestley-construction-banner-05.jpg)",
          }}
        >
          <div className="o-Container c-Banner_Content">
            <h1 className="c-Banner_Title">Our Values</h1>
            <h3 className="o-M30 o-P20 c-Banner_Subheading">
              Speed, honesty and sustainable practices – that’s what makes us
              special. Our reputation is built on robust values&hellip;
            </h3>
          </div>
        </div>
      </header>
      <div className="o-Section o-Section-bg-grey-lighter">
        <div className="o-Container">
          <div className="o-Flex o-Flex-center o-Section_Cols">
            <div className="o-Section_Col c-Content c-Content-limit">
              <h2>Honesty is the best policy</h2>
              <p>
                Unforeseen circumstances can happen. It’s part of life in
                construction. But we’re upfront at every stage of our projects,
                ensuring you’re always kept up to date with developments. We act
                with integrity; if we’ve agreed to deliver it, we’ll find a way
                – and it will be done to the highest standard.
              </p>
            </div>
            <div className="o-Section_Col">
              <figure className="c-Figure c-Figure-right">
                <img
                  src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2018/05/21014712/priestley-construction-under-construction-03.jpg"
                  className="c-Image c-Image-full-width"
                  loading="lazy"
                  width="1010"
                  height="672"
                  title="priestley-construction-under-construction-03"
                  alt=""
                />
              </figure>
            </div>
          </div>
        </div>
      </div>
      <div className="o-Section o-Section-bg-grey-lighter">
        <div className="o-Container">
          <div className="o-Flex o-Flex-center o-Section_Cols o-Section_Cols-reverse">
            <div className="o-Section_Col c-Content c-Content-limit">
              <h2>Money isn’t everything</h2>
              <p>
                High-yielding apartments, modern office space,
                high-specification homes and more can be realised without the
                need for excessive spending. Money is not our driving force –
                you are. Clients trust us to provide an honest appraisal of
                where value can be achieved throughout the build schedule to
                maximise returns on their development. Let us identify savings
                and save you the hassle.
              </p>
            </div>
            <div className="o-Section_Col">
              <figure className="c-Figure c-Figure-left">
                <img
                  src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2018/05/21014534/priestley-construction-22-clarendon-rd-1.jpg"
                  className="c-Image c-Image-full-width"
                  loading="lazy"
                  width="1010"
                  height="672"
                  title="priestley-construction-22-clarendon-rd"
                  alt=""
                />
              </figure>
            </div>
          </div>
        </div>
      </div>
      <div className="o-Section o-Section-bg-grey-lighter">
        <div className="o-Container">
          <div className="o-Flex o-Flex-center o-Section_Cols">
            <div className="o-Section_Col c-Content c-Content-limit">
              <h2>Time is of the essence</h2>
              <p>
                No one can beat us for swift results. We shave down delivery
                time at no cost to the quality of your finish. By taking plenty
                of advance steps – from pre-planning and location reviews to
                maintaining regular communication – Priestley Construction can
                complete your vision.
              </p>
            </div>
            <div className="o-Section_Col">
              <figure className="c-Figure c-Figure-right">
                <img
                  src="https://priestleyconstruction-co-uk.s3.eu-west-1.amazonaws.com/app/uploads/2018/05/21014225/priestley-construction-18-john-st.jpg"
                  className="c-Image c-Image-full-width"
                  loading="lazy"
                  width="1010"
                  height="672"
                  title="priestley-construction-18-john-st"
                  alt=""
                />
              </figure>
            </div>
          </div>
        </div>
      </div>

      <div className="o-Section o-Section-bg-grey-lighter o-Section-no-switch">
        <div className="o-Center c-Newsletter">
          <h2 className="o-M30 c-Title u-TextAlignCenter">Hear more from us</h2>
          <p className="o-M30 u-TextAlignCenter">
            Get the inside scoop on our VIP launches, early bird offers, latest
            projects, company news and more.
          </p>
        </div>
      </div>
    </main>
  );
};

export default OurValues;
